import {z} from 'zod';
import {zAbsoluteDate} from '../schemas';
import {
  AccountInstalmentRangeResponse,
  accountResponse,
} from '../schemas/account';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';

export const accountInstalmentRange = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /account/instalment-range',
  requestSchema: z.object({
    accountId: z.string().uuid(),
    startDate: zAbsoluteDate,
  }),
  queryKey: e => ['account', 'instalment-range', e?.accountId, e?.startDate],
  responseSchema: AccountInstalmentRangeResponse,
});

export const addContactToAccount = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /account/add-contact',
  requestSchema: z.object({
    verificationCode: z.string().min(1).trim().optional(),
    accountExternalId: z.string().min(1).trim(),
    accountType: z.string().min(1).trim(),
  }),
  responseSchema: null,
});

export const getAccountByExternalId = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getAccountByExternalId',
  queryKey: data => ['account', data],
  requestSchema: z.object({
    verificationCode: z.string().min(1).trim().optional(),
    accountExternalId: z.string().min(1).trim(),
    accountType: z.string().min(1).trim(),
  }),
  responseSchema: accountResponse.nullable(),
});

export const getAccountByExternalIdAnonymously = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'GET /getAccountByExternalIdAnonymously',
  queryKey: data => ['account', data],
  requestSchema: z.object({
    billerSlug: z.string().min(1).trim(),
    verificationCode: z.string().min(1).trim().optional(),
    accountExternalId: z.string().min(1).trim(),
    accountType: z.string().min(1).trim(),
  }),
  responseSchema: accountResponse.nullable(),
});
