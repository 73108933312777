import 'url-search-params-polyfill';
import fromEntries from 'object.fromentries';
import {resolveSlugFromHost} from 'payble-shared';

export function getUrlParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = fromEntries(urlSearchParams.entries());
  return params;
}

export function mergeSearchParams(
  previousParams: URLSearchParams | Record<string, string>,
  searchParams?: URLSearchParams | Record<string, string | undefined | null>
) {
  const nextSearchParams = new URLSearchParams(previousParams);

  if (!searchParams) return nextSearchParams;

  const overrideSearchParams =
    searchParams instanceof URLSearchParams
      ? searchParams
      : Object.entries(searchParams);

  for (const [key, value] of overrideSearchParams) {
    if (
      value === '' ||
      value === null ||
      value === undefined ||
      value === 'null' ||
      value === 'undefined'
    ) {
      nextSearchParams.delete(key);
    } else {
      nextSearchParams.set(key, value);
    }
  }
  return nextSearchParams;
}

export function isSearchParamsEqual(
  searchParams1: URLSearchParams,
  searchParams2: URLSearchParams
): boolean {
  const keys1 = Array.from(searchParams1.keys());
  const keys2 = Array.from(searchParams2.keys());

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key)) return false;
    if (searchParams1.get(key) !== searchParams2.get(key)) {
      return false;
    }
  }

  for (const key of keys2) {
    if (!keys1.includes(key)) return false;
    if (searchParams1.get(key) !== searchParams2.get(key)) {
      return false;
    }
  }

  return true;
}

export function searchParamsToObject(searchParams: URLSearchParams) {
  return [...searchParams].reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {}
  );
}

export function cleanParams<
  T extends Record<string, boolean | number | string | undefined | null>,
>(params: T) {
  const cleanedParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value === '' || value === null || value === undefined) return acc;
    return {
      ...acc,
      [key]: value,
    };
  }, {} as T);
  return cleanedParams;
}

export const getBillerSlugFromUrl = () => {
  const url = window.location.pathname;
  const slug = url.split('/')[2];
  const host = window.location.hostname;
  const resolvedSlug = resolveSlugFromHost(host);
  return resolvedSlug ?? slug;
};

export const getBillerFriendlyNameFromSlug = (slug: string) => {
  switch (slug) {
    case 'city-of-exampleton':
      return 'City of Exampleton';
    case 'kingston':
      return 'Kingston';
    case 'energy-on-v1':
      return 'Energy On';
    case 'moreton-bay':
      return 'City of Moreton Bay';
    case 'cessnock-city-council':
      return 'Cessnock City Council';
    case 'city-of-west-torrens':
      return 'City of West Torrens';
    case 'wingecarribee-shire-council':
      return 'Wingecarribee Shire Council';
    case 'vic-park-council':
      return 'Town of Victoria Park';
    case 'agl-energy':
      return 'AGL Energy';
    case 'ku-ring-gai-council':
      return 'Ku-ring-gai Council';
    case 'city-of-npsp':
      return 'City of Norwood Payneham & St Peters';
    case undefined:
    case null:
      return 'your';
    default:
      return titleCaseEveryWord(slug.replace(/-/g, ' '));
  }
};

export function titleCaseEveryWord(str: string) {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
