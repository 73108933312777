import {z} from 'zod';
import {FormConfig} from '../../types';

export const support = {
  title: 'Support',
  configType: 'biller',

  validation: z.object({
    email: z.string().trim(),
    address: z.string().trim(),
    phone: z.string().trim(),
    abn: z.string().trim(),
    url: z.string().trim(),
    supportWebsite: z.string().trim(),
    openingTimes: z.string().trim(),
    directDebitServiceAgreement: z.string().trim(),
    directDebitServicePaperForm: z.string().trim(),
    hardshipLink: z.string().trim(),

    // Not technically support but only used for support (?)
    directDebitUserId: z.string().trim(),
  }),

  form: [
    {type: 'text', label: 'Email', name: 'email'},
    {type: 'text', label: 'Address', name: 'address'},
    {type: 'text', label: 'Phone', name: 'phone'},
    {type: 'text', label: 'ABN', name: 'abn'},
    {type: 'text', label: 'URL', name: 'url'},
    {type: 'text', label: 'Support Website', name: 'supportWebsite'},
    {type: 'text', label: 'Opening Times', name: 'openingTimes'},
    {
      type: 'text',
      label: 'Direct Debit Service Agreement',
      name: 'directDebitServiceAgreement',
    },
    {
      type: 'text',
      label: 'Direct Debit Service Paper Form',
      name: 'directDebitServicePaperForm',
    },
    {type: 'text', label: 'Hardship Link', name: 'hardshipLink'},

    {type: 'text', name: 'directDebitUserId', label: 'Direct Debit User ID'},
  ],
} satisfies FormConfig;
