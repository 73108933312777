import {z} from 'zod';
import {zNZBankAccountNumber, zNumberString} from './generic';
import {accountStanding, accountStandingSchema} from './account';

export const paymentMethodType = z.enum([
  'card',
  'direct_debit',
  'nz_direct_debit',
]);

export const paymentMethodStatus = z.enum(['active', 'disabled', 'removed']);

export const paymentMethodResponse = z.object({
  id: z.string(),
  type: paymentMethodType,
  status: paymentMethodStatus,
  reason: z.string().optional(),
  card: z.union([
    z.null(),
    z
      .object({
        holderName: z.string(),
        brand: z.string(),
        last4: z.string(),
        expiryMonth: z.number(),
        expiryYear: z.number(),
      })
      .optional(),
  ]),
  bank: z.union([
    z.null(),
    z
      .object({
        bsb: z.string(),
        name: z.string(),
        accountNumber: z.string(),
        accountName: z.string(),
      })
      .optional(),
  ]),
  nzBank: z.union([
    z.null(),
    z
      .object({
        name: z.string(),
        accountNumber: zNZBankAccountNumber,
        accountName: z.string(),
      })
      .optional(),
  ]),
});

export type PaymentMethodResponse = NonNullable<
  z.infer<typeof paymentMethodResponse>
>;

export const contactSourceSchema = z.enum(['profile', 'setup']);

export const contactResponse = z.object({
  id: z.string(),
  billerId: z.string(),
  title: z.string(),
  givenName: z.string(),
  familyName: z.string(),
  email: z.string().email().optional(),
  emailVerified: z.boolean(),
  mobile: z.string().optional(),
  mobileVerified: z.boolean(),
  sendingEmailReceipts: z.boolean(),
  paymentMethods: z.union([
    z.null(),
    z.array(paymentMethodResponse).optional(),
  ]),
});

export type ContactResponse = NonNullable<z.infer<typeof contactResponse>>;

const contactAccountResponse = z.object({
  accountId: z.string(),
  externalId: z.string(),
  amountOwing: z.number(),
  description: z.string(),
  type: z.string(),
  standing: accountStandingSchema.optional(),
});

export const filterContactsSchema = z.object({
  contact: z
    .object({
      mobile: z.string().optional(),
      mobileVerified: z.boolean().optional(),
      email: z.string().optional(),
      emailVerified: z.boolean().optional(),
    })
    .optional(),
  account: z
    .object({
      externalId: z.string().optional(),
      types: z.array(z.string()).optional(),
      amountOwing: z
        .object({
          from: z.optional(zNumberString),
          to: z.optional(zNumberString),
        })
        .optional(),
    })
    .optional(),
  standing: z
    .object({
      statuses: z.array(accountStanding).optional(),
    })
    .optional(),
});

export type FilterContactsSchema = NonNullable<
  z.infer<typeof filterContactsSchema>
>;

export const filteredContactResponse = z.object({
  billerId: z.string(),
  contactId: z.string(),
  name: z.string(),
  email: z.string().optional(),
  emailVerified: z.boolean(),
  mobile: z.string(),
  mobileVerified: z.boolean(),
  archived: z.boolean(),
  accounts: z.array(contactAccountResponse),
});

export type FilteredContactResponse = NonNullable<
  z.infer<typeof filteredContactResponse>
>;
