import {useState} from 'react';
import {analytics} from '../../../analytics/hooks/useAnalytics';
import {useUser} from 'lib/auth/useUser';
import {useAPIMutation} from 'lib/api';

export const useEmailVerificationSend = () => {
  const [canReSend, setCanReSend] = useState<boolean>(false);

  const {
    mutateAsync: sendEmail,
    isPending: loading,
    isSuccess: called,
    error,
  } = useAPIMutation('sendEmailVerification', {
    query: {
      onError: () => {
        setCanReSend(true);
      },
      onSuccess: () => {
        analytics.addEvent('email_verification_completed');
      },
    },
  });
  const user = useUser();

  if (!user.contact) {
    throw new Error('No contact found in context');
  }

  const {emailVerified, email} = user.contact ?? {};

  const send = () =>
    email
      ? sendEmail(null as never)
      : Promise.reject(
          new Error('No email found when dispatching email verification')
        );

  if (emailVerified) {
    return {
      state: 'VERIFIED' as const,
      email,
    };
  }

  if (!email) {
    return {
      state: 'NO_EMAIL' as const,
    };
  }

  if (error) {
    return {
      state: 'ERROR' as const,
      errorMessage: error.message,
      send,
      email,
    };
  }

  if (loading) {
    return {
      state: 'LOADING' as const,
      email,
    };
  }

  if (canReSend && called) {
    return {
      state: 'RESEND' as const,
      email,
      send,
    };
  }

  if (called) {
    return {
      state: 'SENT' as const,
      email,
    };
  }

  return {
    state: 'PENDING' as const,
    email,
    send,
  };
};
