import React, {useEffect} from 'react';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {Debbie} from 'components/organisms/Debbie';
import {AccountWarningModal} from '../components/AccountWarningModal';
import {ConfirmLinkAccount} from '../components/ConfirmLinkAccount';
import {Loading} from 'components/atoms/Loading';
import {useAPIMutation} from 'lib/api';
import {useSetupRoute} from '../components/SetupRoute';

export const LinkContactPlanRequest: React.FC = () => {
  const billerConfig = useBillerConfig();
  const {account} = useSetupRoute();
  const navigate = useSetupNavigate();
  const {verificationCode, accountType} = useSetupSearchParams();

  const {mutateAsync: linkAccount, isPending: loading} = useAPIMutation(
    'addContactToAccount',
    {
      query: {
        onSuccess() {
          navigate('/biller/:slug/setup/request-plan');
        },
        onError(e) {
          navigate('/biller/:slug');
          throw e;
        },
      },
    }
  );

  useEffect(() => {
    if (!billerConfig.hasRequestPlan) {
      navigate('/biller/:slug/setup');
    }
  }, [accountType]);

  const onLinkAccount = async () => {
    await linkAccount({
      accountExternalId: account.externalId,
      accountType: account.type,
      verificationCode,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Debbie title="Is this the property you were looking for?" />
      <AccountWarningModal onConfirm={onLinkAccount} />
      <ConfirmLinkAccount
        account={account}
        onLinkAccount={onLinkAccount}
        onLinkButtonText="Continue"
      />
    </>
  );
};
