import {useEffect} from 'react';
import {getWelcomeDebbieContent} from 'features/setup/helpers';
import {useGetAccountTypeInfo} from '../hooks/useGetAccountTypeInfo';
import {useGetAccountFromSearchParams} from '../../../lib/account/useGetAccountFromSearchParams';
import {Debbie} from 'components/organisms/Debbie';
import {LookupAccount} from '../components/LookupAccount';
import {ConfirmLinkAccount} from '../components/ConfirmLinkAccount';
import {Loading} from 'components/atoms/Loading';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useInfringementNavigate} from 'features/infringement/hooks/useInfringementNavigate';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {AccountWarningModal} from '../components/AccountWarningModal';
import {useUser} from 'lib/auth/useUser';
import {useAPIMutation} from 'lib/api';
import {useConsumerAppConfig} from '../../../lib/appConfig/ConsumerAppConfig';

const SUPPORTS_PAYMENT_CATEGORY = 'notice';

export const Welcome = () => {
  const accountTypeInfo = useGetAccountTypeInfo();
  const user = useUser();
  const {
    loading: loadingAccount,
    data: account,
    error,
    requiresVerification,
  } = useGetAccountFromSearchParams();
  const {lookingUpAnother, verificationCode, accountType, patch} =
    useSetupSearchParams();
  const navigate = useSetupNavigate();
  const infringementNavigate = useInfringementNavigate();

  const billerConfig = useBillerConfig();
  const biller = useConsumerAppConfig().biller;

  useEffect(() => {
    if (accountType === 'infringements') {
      infringementNavigate('/confirm-payment', undefined, undefined, true);
    }
  }, [accountType]);

  const {mutateAsync: linkAccount, isPending} = useAPIMutation(
    'addContactToAccount',
    {
      query: {
        onSuccess() {
          navigate('/biller/:slug/setup/options', {
            verificationCode,
          });
        },
        onError(e) {
          navigate('/biller/:slug');
          throw e;
        },
      },
    }
  );

  const loading = loadingAccount || isPending;

  const accountCanSetupInstallmentPlan = (balance: number) => {
    return billerConfig.canPayZeroBalanceAccount || balance > 0;
  };

  const {title, message} = getWelcomeDebbieContent({
    biller,
    includeGetStarted: !loading && !account,
    contact: user.contact,
    account,
    externalIdLabel: accountTypeInfo.externalIdLabel,
    canPayZeroBalanceAccount: billerConfig.canPayZeroBalanceAccount,
  });

  const showLookupForm =
    lookingUpAnother ||
    (!loading && !account) ||
    (account && !accountCanSetupInstallmentPlan(account.amountOwing));

  const showConfirmLinking =
    !lookingUpAnother &&
    !loading &&
    account &&
    accountCanSetupInstallmentPlan(account.amountOwing);

  const onLinkAccount = () => {
    account &&
      linkAccount({
        accountExternalId: account.externalId,
        accountType: account.type,
        verificationCode,
      });
  };

  return (
    <>
      {showLookupForm ? (
        <>
          <Debbie title={title} message={message} />
          <LookupAccount
            loading={loading}
            error={error}
            requiresVerification={requiresVerification}
            onSubmit={values => {
              patch({...values, lookingUpAnother: ''});
            }}
            supportedPaymentCategory={SUPPORTS_PAYMENT_CATEGORY}
          />
        </>
      ) : null}

      {loading ? <Loading /> : null}

      {showConfirmLinking ? (
        <>
          <Debbie title="Is this the property you were looking for?" />
          <AccountWarningModal onConfirm={onLinkAccount} />
          <ConfirmLinkAccount
            account={account}
            onLinkAccount={onLinkAccount}
            onLookupAnotherAccount={() => {
              patch({lookingUpAnother: '1'});
            }}
          />
        </>
      ) : null}
    </>
  );
};
