import React from 'react';
import * as Sentry from '@sentry/react';

import * as API from 'lib/api';
import * as Auth from 'lib/auth/Auth';
import * as BillerConfig from 'lib/appConfig/BillerConfig';

import {Navigation} from 'features/navigation/Navigation';

import './tailwind.css';
import {ErrorPage} from 'features/navigation/Error';

import _refiner from 'refiner-js';
import {LivenessWrapper} from './lib/appConfig/LivenessWrapper';
import {VersionPoll} from 'components/organisms/VersionPoll';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {PaybleUI} from 'payble-ui';
import {PublicHolidayNotice} from 'features/maintenance/PublicHolidayNotice';
import {ConsumerAppConfigProvider} from './lib/appConfig/ConsumerAppConfig';
import {makeErrorInvalidator, makeInvalidator} from 'payble-api-react-query';
import {consumerQueryConfig} from 'payble-api-client';
import {Devtools} from './Devtools';

_refiner('setProject', '785842d0-f9bf-11ec-8c7c-9962ba52a877');

const queryClient = new QueryClient();

API.api.successTopic.subscribe(
  makeInvalidator(queryClient, consumerQueryConfig)
);
API.api.errorTopic.subscribe(
  makeErrorInvalidator(queryClient, consumerQueryConfig)
);

const SentryError = () => <ErrorPage />;

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={SentryError}>
      <API.Provider>
        <QueryClientProvider client={queryClient}>
          <Devtools />
          <ConsumerAppConfigProvider>
            <BillerConfig.Provider>
              <LivenessWrapper>
                <Auth.Provider>
                  <PaybleUI>
                    <PublicHolidayNotice />
                    <Navigation />
                  </PaybleUI>
                </Auth.Provider>
              </LivenessWrapper>
              <VersionPoll />
            </BillerConfig.Provider>
          </ConsumerAppConfigProvider>
        </QueryClientProvider>
      </API.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
