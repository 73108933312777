import React from 'react';
import {XMarkIcon} from '@heroicons/react/20/solid';
import {Debbie} from 'components/organisms/Debbie';
import {Form, Formik} from 'formik';
import {getBillerFriendlyNameFromSlug, getBillerSlugFromUrl} from 'lib/url';
import {Loading} from 'components/atoms/Loading';
import {LookupAccountExternalIdInput} from 'features/setup/components/LookupAccountExternalIdInput';
import {LookupAccountSubmitButton} from 'features/setup/components/LookupAccountSubmitButton';
import {useInfringementNavigate} from '../hooks/useInfringementNavigate';
import {useInfringementParams} from '../hooks/useInfringementParams';
import {errs, getBillerAccountTypeInfo, possessive} from 'payble-shared';
import {NotFound} from 'features/navigation/NotFound';
import {useAPIMutation} from 'lib/api';
import {DomainError} from 'payble-shared/src/errs';
import {APIOutput} from 'payble-api-client';

type FormValue = {
  accountExternalId?: string;
};

function getErrorMessage({
  error,
  data,
  isSuccess,
}: {
  error?: DomainError | null;
  data?: APIOutput<'consumer', 'getAccountByExternalIdAnonymously'>;
  isSuccess?: boolean;
}) {
  if (error) {
    return error?.message;
  }

  if (isSuccess && !data) {
    return 'Record not found';
  }

  return '';
}

export const LookupAccount = () => {
  const navigate = useInfringementNavigate();
  const {accountType, accountExternalId} = useInfringementParams();
  const billerSlug = getBillerSlugFromUrl();
  const accountTypeInfo = getBillerAccountTypeInfo(billerSlug, accountType);

  const getAccountByExternalIdAnonymously = useAPIMutation(
    'getAccountByExternalIdAnonymously',
    {
      query: {
        retry: (tries, error) =>
          !(error instanceof errs.AccountVerificationError) && tries < 3,
      },
    }
  );
  const friendlyName = getBillerFriendlyNameFromSlug(billerSlug);

  async function onSubmit({accountExternalId}: FormValue) {
    if (accountExternalId) {
      const account = await getAccountByExternalIdAnonymously.mutateAsync({
        billerSlug,
        accountExternalId,
        accountType,
      });

      if (account?.externalId) {
        navigate('/confirm-payment', {
          accountType,
          accountExternalId: account?.externalId,
        });
      }
    }
  }

  if (!accountTypeInfo) return <NotFound />;

  if (getAccountByExternalIdAnonymously.isPending) return <Loading />;

  const errorMessage = getErrorMessage(getAccountByExternalIdAnonymously);

  return (
    <Formik<FormValue>
      initialValues={{
        accountExternalId,
      }}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className="flex flex-col gap-4">
          <Debbie
            title={`Welcome to ${possessive(
              friendlyName
            )} infringement payment system`}
            message="Enter your infringement number to easily pay or manage your account."
          />
          <div>
            <LookupAccountExternalIdInput
              accountTypeInfo={accountTypeInfo}
              disabled={getAccountByExternalIdAnonymously.isPending}
            />
            {errorMessage ? (
              <div className="flex items-center">
                <XMarkIcon className="w-4 h-4 text-red-500" />
                <span className="mt-1 ml-1 text-sm text-gray-400">
                  {errorMessage}
                </span>
              </div>
            ) : null}
          </div>
          <LookupAccountSubmitButton cta="Lookup infringement" />
        </Form>
      )}
    </Formik>
  );
};
