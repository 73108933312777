import React, {useEffect} from 'react';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {Loading} from 'components/atoms/Loading';
import {useAPIMutation} from 'lib/api';

export const LinkContactInfringement: React.FC = () => {
  const {data: account, loading} = useGetAccountFromSearchParams();
  const navigate = useSetupNavigate();
  const {verificationCode, accountType} = useSetupSearchParams();

  const {mutateAsync: linkAccount} = useAPIMutation('addContactToAccount', {
    query: {
      onSuccess() {
        navigate('/biller/:slug/setup/flexible/installments');
      },
      onError() {
        throw new Error(`Failed to link account with id: ${account?.id}`);
      },
    },
  });

  useEffect(() => {
    if (accountType && accountType !== 'infringements') {
      navigate('/biller/:slug/setup');
    }
  }, [accountType]);

  useEffect(() => {
    if (!loading && account) {
      linkAccount({
        accountExternalId: account.externalId,
        accountType: account.type,
        verificationCode,
      });
    }
  }, [loading, account]);

  return <Loading />;
};
