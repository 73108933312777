import React, {ReactNode} from 'react';
import {useAPIQuery} from '../api';
import {ConsumerAppConfig} from 'payble-shared/src/app-config/ConsumerAppConfig';
import {ErrorPage} from '../../features/navigation/Error';
import {Loading} from 'payble-ui';

const ConsumerAppConfigContext = React.createContext<ConsumerAppConfig | null>(
  null
);

export const useConsumerAppConfig = (): ConsumerAppConfig => {
  const context = React.useContext(ConsumerAppConfigContext);
  if (context === null) {
    throw new Error(
      'useConsumerAppConfig must be used within a ConsumerAppConfigProvider'
    );
  }
  return context;
};

export const ConsumerAppConfigProvider: React.FC<{
  children: ReactNode;
}> = ({children}) => {
  const configQ = useAPIQuery('getConsumerAppConfig', {
    // This is intentionally not included in the query-key
    data: {url: window.location.href},
  });

  if (configQ.error) {
    return <ErrorPage title={configQ.error.message} />;
  }

  if (configQ.data == null) {
    return (
      <div className="flex justify-center items-center h-lvh">
        <Loading />
      </div>
    );
  }

  return (
    <ConsumerAppConfigContext.Provider value={configQ.data}>
      {children}
    </ConsumerAppConfigContext.Provider>
  );
};
