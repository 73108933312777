import React, {ReactElement} from 'react';
import {getBillerFriendlyNameFromSlug, getBillerSlugFromUrl} from 'lib/url';
import {useField} from 'formik';
import {RadioGroupField} from './RadioGroupField';
import {useSetupRoute} from './SetupRoute';
import {PaymentOption} from 'payble-shared';
import {GetAccountByExternalIdResponse} from 'lib/account/useGetAccountFromSearchParams';

export function SetupOptionsRadio() {
  const {account} = useSetupRoute();

  const paymentOptions = account?.paymentOptions ?? [];
  const billerSlug = getBillerSlugFromUrl();

  if (!paymentOptions?.length) {
    throw new Error(`No payment options available for biller ${billerSlug}`);
  }

  const [field] = useField('mode');
  const label = paymentOptions?.find(
    option => option.value === field.value
  )?.label;

  const paymentOptionsWithId = paymentOptions.map(payment => ({
    ...payment,
    id: payment.value.toLowerCase(),
  }));

  return (
    <>
      <RadioGroupField name="mode" options={paymentOptionsWithId ?? []} />
      {field.value ? (
        <PlanWarning
          planMode={field.value}
          planName={label}
          account={account}
          billerSlug={billerSlug}
        />
      ) : null}
    </>
  );
}

type PlanWarningProps = {
  planMode: PaymentOption['value'];
  planName?: string;
  account?: GetAccountByExternalIdResponse | null;
  billerSlug: string;
};

export function PlanWarning({
  planMode,
  planName,
  account,
  billerSlug,
}: Readonly<PlanWarningProps>): null | ReactElement {
  if (planMode === 'PAY_AMOUNT' || !planName) {
    return null;
  }

  if (!account?.hasLegacyPlan) {
    return null;
  }

  const billerName = getBillerFriendlyNameFromSlug(billerSlug);

  return (
    <div className="px-1 mt-2">
      <p className="text-sm">
        Signing up to {planName} will automatically replace your existing
        payment plan with {billerName}, this can take 2 days to be processed.
      </p>
    </div>
  );
}
