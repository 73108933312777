import React, {useEffect} from 'react';
import {useParams} from 'react-router';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {ConfirmationPayInFull} from 'features/setup/components/ConfirmationPayInFull';
import {useAPIQuery} from 'lib/api';

// View a payment made on an account, specifically one which was a "pay in full"
export const Payment: React.FC = () => {
  const {paymentId} = useParams<{paymentId: string}>();

  useEffect(() => {
    document.title = 'Payble - Payment Confirmation';
  }, []);

  const {
    data,
    isFetching: loading,
    error,
  } = useAPIQuery('getPayment', {
    data: paymentId
      ? {
          paymentId,
        }
      : undefined,
  });

  if (!paymentId) return <ErrorMessage message="No payment ID" />;

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!data) return <>No payment found</>;

  return <ConfirmationPayInFull paymentId={paymentId} manage={true} />;
};
