import React from 'react';
import {Form} from 'payble-ui';
import {z} from 'zod';
import {useAuth} from 'lib/auth/Auth';
import {zPhoneNumber} from 'payble-api-client/schemas/generic';
import {useConsumerAppConfig} from '../../../lib/appConfig/ConsumerAppConfig';

type SendCodeProps = {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  onSent: () => void;
};

export const SendCode: React.FC<SendCodeProps> = ({
  phoneNumber,
  setPhoneNumber,
  onSent,
}) => {
  const {sendOTP} = useAuth();
  const {mobileNumberPlaceholder} = useConsumerAppConfig();

  return (
    <div className="flex flex-col items-center justify-center gap-4 mx-auto w-72">
      <p className="mt-8 text-lg font-bold text-center w-72 text-navy">
        Enter your mobile number
      </p>

      <p className="text-sm text-center text-gray-600 w-72">
        You will receive an SMS code to confirm your identity. By entering your
        phone number you consent to Payble contacting you by SMS with a 6 digit
        one time code.
      </p>

      <Form
        className="w-full"
        schema={z.object({
          phoneNumber: zPhoneNumber,
        })}
        defaultValues={{
          phoneNumber,
        }}
        onSubmit={async data => {
          setPhoneNumber(data.phoneNumber);

          const {formattedPhoneNumber} = await sendOTP({
            mobileNumber: data.phoneNumber,
          });

          setPhoneNumber(formattedPhoneNumber);

          onSent();
        }}
      >
        <Form.InputPhone
          name="phoneNumber"
          placeholder={mobileNumberPlaceholder}
          className="text-xl"
        />

        <Form.SubmitButton>Send my SMS code</Form.SubmitButton>
      </Form>
    </div>
  );
};
