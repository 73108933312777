import {navigate as navigateTo} from 'lib/navigation/routes';
import {goBack} from 'lib/navigation/routes';
import {useEffect, useState} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import * as Sentry from '@sentry/react';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {Debbie} from 'components/organisms/Debbie';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {classNames} from 'lib/styles';
import {toPlanSummary, getPlanFrequencyInputs} from 'lib/getPlans';
import {useSetupRoute} from '../components/SetupRoute';
import {StyledRadioGroup} from '../components/StyledRadioGroup';
import NumberFormat from 'react-number-format';
import {AbsoluteDate, formatToDollars} from 'payble-shared';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {Loading} from 'components/atoms/Loading';
import {useAPIQuery} from 'lib/api';
import {APIInput} from 'payble-api-client';

export const CatchupSchedule = () => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = useBillerConfig();
  const {account} = useSetupRoute();
  const navigate = useSetupNavigate();
  const {instalmentStartAt, instalmentTargetDate, instalmentFrequency, patch} =
    useSetupSearchParams();

  const [previewInstalmentPlanInput, setPreviewInstalmentPlanInput] =
    useState<APIInput<'consumer', 'previewInstalmentPlan'>>();

  const {
    data: previewInstalmentPlan,
    error,
    isFetching: loading,
  } = useAPIQuery('previewInstalmentPlan', {
    data: previewInstalmentPlanInput ?? undefined,
  });

  const targetDate = account.catchUp?.targetDate ?? null;

  if (!targetDate) {
    return null;
  }

  const today = AbsoluteDate.today({billerConfig});
  const maxDate = targetDate;

  useEffect(() => {
    if (error) {
      Sentry.captureException(
        new Error(
          `Could not load instalment preview: biller: ${
            billerSlug ?? 'slug missing'
          } ${error.message}`
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!billerConfig.hasCatchUpPlans || !account?.catchUp) {
      navigateTo('/biller/:slug/setup', {slug: billerSlug});
    }
  }, [billerConfig]);

  type FetchPlans = {
    startDate?: AbsoluteDate | null;
    targetDate?: AbsoluteDate | null;
  };

  const fetchPlans = async ({
    startDate = null,
    targetDate = null,
  }: FetchPlans) => {
    if (!instalmentStartAt || !instalmentTargetDate) {
      return null;
    }
    setPreviewInstalmentPlanInput(
      getPlanFrequencyInputs({
        startDate: startDate ?? instalmentStartAt,
        accountId: account.id,
        payMode: 'catch-up',
        targetDate: targetDate ?? instalmentTargetDate,
      })
    );
  };

  const defaultStartAtISO = instalmentStartAt?.toISO() ?? '';
  const defaultTargetDateISO = instalmentTargetDate?.toISO() ?? maxDate.toISO();

  useEffect(() => {
    patch(
      {
        instalmentFrequency: 'weekly',
        mode: 'PAY_CATCH_UP',
        instalmentStartAt: defaultStartAtISO,
        instalmentTargetDate: defaultTargetDateISO,
      },
      {replace: true}
    );
  }, []);

  useEffect(() => {
    if (!previewInstalmentPlan?.length && !loading && !error) {
      fetchPlans({});
    }
  }, [instalmentStartAt, instalmentTargetDate]);

  const plans = previewInstalmentPlan
    ?.map(plan => {
      if (!plan || !plan.frequency) {
        return undefined;
      }

      const summary = toPlanSummary({
        balancedInstalmentPreview: plan.balancedInstalmentPreview,
        frequency: plan.frequency,
        onError: Sentry.captureException,
      });

      if (!summary) {
        return undefined;
      }

      return {
        planAmount: plan.amount,
        ...summary,
      };
    })
    .filter((plan): plan is NonNullable<typeof plan> => plan !== undefined);

  return (
    <>
      <Debbie
        title={
          instalmentStartAt && instalmentTargetDate
            ? 'How does this look to you?'
            : 'Choose when payments will start and end'
        }
      />
      <div className="flex flex-col items-center">
        <div className="grid w-full gap-6 mt-6 md:grid-cols-2">
          <div className="space-y-4">
            <h4 className="font-semibold">Start date</h4>
            <input
              id="startDate"
              name="startDate"
              type="date"
              min={today.toISO()}
              max={maxDate.toISO()}
              className="w-full text-center transition bg-white border-transparent rounded-md shadow-sm hover:border-blue-500 border-1"
              onChange={e => {
                if (!e.target.value) {
                  return;
                }
                patch(
                  {
                    instalmentStartAt: AbsoluteDate.fromISO(
                      e.target.value
                    ).toISO(),
                  },
                  {replace: true}
                );

                fetchPlans({
                  startDate: AbsoluteDate.fromISO(e.target.value),
                });
              }}
              defaultValue={defaultStartAtISO}
            />
          </div>
          <div className="space-y-4">
            <h4 className="font-semibold">End date</h4>
            <input
              id="targetDate"
              name="targetDate"
              type="date"
              min={today.toISO()}
              max={maxDate.toISO()}
              className="w-full text-center transition bg-white border-transparent rounded-md shadow-sm hover:border-blue-500 border-1"
              onChange={e => {
                if (!e.target.value) {
                  return;
                }
                patch(
                  {
                    instalmentTargetDate: AbsoluteDate.fromISO(
                      e.target.value
                    ).toISO(),
                  },
                  {replace: true}
                );

                fetchPlans({
                  targetDate: AbsoluteDate.fromISO(e.target.value),
                });
              }}
              defaultValue={defaultTargetDateISO}
            />
          </div>
        </div>
        {account.catchUp?.amount && (
          <div className="mt-6">
            <div className="mb-2">
              <h2 className="text-lg font-semibold text-gray-900">
                Total: ${formatToDollars(account.catchUp?.amount)}
              </h2>
            </div>
            <p className="text-sm text-gray-600">
              Paying off your plan earlier will result in less interest being
              accrued and lower your total Payment.
            </p>
          </div>
        )}

        {!plans && loading && (
          <div className="min-h-[464px]">
            <Loading />
          </div>
        )}

        {plans && (
          <StyledRadioGroup
            options={plans.map(plan => ({
              id: `plan-frequency-${plan.frequency}`,
              label: (
                <p className="flex items-baseline mt-4 text-gray-900">
                  <span className="text-3xl font-extrabold tracking-tight">
                    <NumberFormat
                      value={formatToDollars(plan.amountPerInstalment)}
                      displayType={'text'}
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                  <span className="ml-1 text-xl font-semibold">
                    /{plan.frequency}
                  </span>
                </p>
              ),
              description: (
                <span
                  className={classNames(
                    'text-slate-400 text-left mt-1 block py-1 border border-transparent rounded-md font-xs italic'
                  )}
                >
                  {plan.instalmentCount} {plan.frequency} payments of{' '}
                  <NumberFormat
                    value={formatToDollars(plan.amountPerInstalment)}
                    displayType={'text'}
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />{' '}
                  {plan?.remainderAmount && (
                    <>
                      each, and a final payment of{' '}
                      <NumberFormat
                        value={formatToDollars(plan.remainderAmount)}
                        displayType={'text'}
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </>
                  )}
                  {'. '}
                  {account.catchUp?.amount &&
                  account.catchUp?.amount < plan.planAmount ? (
                    <strong>
                      Estimated interest $
                      {formatToDollars(
                        plan.planAmount - account.catchUp.amount
                      )}
                    </strong>
                  ) : (
                    ''
                  )}
                </span>
              ),
              value: plan.frequency,
            }))}
            onChange={instalmentFrequency =>
              patch({instalmentFrequency}, {replace: true})
            }
            value={instalmentFrequency ?? ''}
          />
        )}

        <h4 className="w-full mt-6 mb-2 text-base font-medium text-left">
          Important notice on interest calculations:
        </h4>
        <p className="text-sm text-gray-600">
          If you are in arrears or are making a partial payment, it is important
          to know that interest is accrued daily on amounts outstanding after
          the due date.
          <br />
          <br />
          Please refer to your rates notice for more information.
        </p>

        <button
          type="button"
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          disabled={
            !instalmentStartAt || !instalmentFrequency || !instalmentTargetDate
          }
          onClick={() =>
            navigate('/biller/:slug/setup/plan/preview', {
              instalmentMode: 'catch-up',
              instalmentFrequency,
            })
          }
        >
          Next
        </button>
        <button
          className="mt-6 text-blue-600 transition hover:text-blue-700"
          onClick={goBack}
        >
          Back
        </button>
      </div>
    </>
  );
};
