import {Debbie} from 'components/organisms/Debbie';
import {Form, Formik} from 'formik';
import {RadioGroupField} from '../components/RadioGroupField';
import {goBack} from 'lib/navigation/routes';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupRoute} from '../components/SetupRoute';

export const SmoothPayNext = () => {
  const {account} = useSetupRoute();
  const navigate = useSetupNavigate();
  const {instalmentFrequency} = useSetupSearchParams();
  const hasInstalments = !!account.targetInstalments?.length;

  const selectNext = instalmentFrequency ? 'amount' : 'frequency and amount';

  const options = [
    {
      value: 'regular',
      label: 'Yes, let’s schedule regular payments to get ahead',
      description: `Payments will begin after you’ve paid your current ${account.type} notice. You’ll select the ${selectNext} on the next page.`,
    },
    {
      value: 'no-regular',
      label: `No, I’ll pay the next ${account.type} when they’re due`,
      description:
        'Select this if you’d rather wait until your next notice is issued before making any payments.',
    },
  ];
  return (
    <Formik
      initialValues={{
        nextNotice: 'regular',
      }}
      onSubmit={({nextNotice}) => {
        if (nextNotice === 'regular') {
          if (instalmentFrequency) {
            navigate('/biller/:slug/setup/smooth/amount');
          } else {
            navigate('/biller/:slug/setup/smooth/frequency');
          }
        } else {
          if (hasInstalments) {
            navigate('/biller/:slug/setup/plan/preview', {
              nextNotice,
              instalmentMode: 'smooth-pay',
              instalmentFrequency,
            });
          } else {
            navigate('/biller/:slug/setup/auto', {
              mode: 'PAY_AUTO',
              instalmentMode: 'pay-x-on-y',
              instalmentFrequency: 'automatic',
            });
          }
        }
      }}
    >
      <Form>
        <div className="relative flex flex-col h-full">
          <Debbie
            title="Before we confirm, how about your next notice?"
            message={`Once you've paid off your current ${account.type}, do you want to get ahead on your next notice by making some regular payments in advance?`}
          />
          <div className="flex flex-col items-center justify-center flex-1 h-full">
            <RadioGroupField name="nextNotice" options={options} />
            <button
              type="submit"
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Next
            </button>
            <button
              onClick={goBack}
              className="mt-6 text-blue-600 transition hover:text-blue-700"
            >
              Back
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
