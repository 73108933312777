import {RadioGroup} from '@headlessui/react';
import {
  CalendarIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  TagIcon,
} from '@heroicons/react/24/outline';
import {EllipsisVerticalIcon} from '@heroicons/react/20/solid';
import {buttonClasses, classNames} from 'lib/styles';
import React, {Dispatch, useEffect, useRef, useState} from 'react';
import {Options} from '../pages/Cancel';
import {CancelReason} from './CancelReason';
import {APIOutput} from 'payble-api-client';

type CancelOptionsProps = {
  selected: Options;
  setSelected: Dispatch<React.SetStateAction<Options>>;
  reason: string;
  setReason: Dispatch<React.SetStateAction<string>>;
  setShowNext: Dispatch<React.SetStateAction<boolean>>;
  instalmentPlan: NonNullable<APIOutput<'consumer', 'getInstalmentPlan'>>;
};

function getAccountTypeOptions(accountType: string) {
  if (accountType === 'infringements') {
    return Object.values(Options).filter(o => o !== Options.OwnershipChange);
  }

  return Object.values(Options);
}

export const CancelOptions: React.FC<CancelOptionsProps> = ({
  selected,
  setSelected,
  reason,
  setReason,
  setShowNext,
  instalmentPlan,
}) => {
  const [canGoNext, setCanGoNext] = useState<boolean>(false);
  const nextBtnRef = useRef<HTMLButtonElement>(null);
  const optionsToIconsMapping = {
    [Options.PaymentSchedule]: <CalendarIcon className="w-6 text-gray-400" />,
    [Options.PaymentMethod]: <CreditCardIcon className="w-6 text-gray-400" />,
    [Options.IncorrectBalance]: (
      <CurrencyDollarIcon className="w-6 text-gray-400" />
    ),
    [Options.OwnershipChange]: <TagIcon className="w-6 text-gray-400" />,
    [Options.Other]: <EllipsisVerticalIcon className="w-6 text-gray-400" />,
  };

  useEffect(() => {
    if (selected !== Options.Other && selected !== Options.NoneSelected) {
      setCanGoNext(true);
    }
  }, [selected]);

  const options = getAccountTypeOptions(instalmentPlan.account.type);

  const onOptionChange = (value: Options) => {
    setSelected(value);
    if (nextBtnRef && nextBtnRef.current) {
      nextBtnRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const onNext = () => {
    if (selected !== Options.Other) {
      setReason(selected);
    }
    setShowNext(true);
  };

  return (
    <div>
      <RadioGroup
        id="options"
        value={selected}
        onChange={onOptionChange}
        className="mb-8"
      >
        <RadioGroup.Label className="text-base font-bold text-center sm:text-lg">
          Reason for cancellation
        </RadioGroup.Label>
        <div className="relative mt-8 -space-y-px bg-white rounded-md">
          {options.map((option, optionIdx) => {
            if (option === '') {
              return;
            }
            return (
              <RadioGroup.Option
                key={option}
                value={option}
                className={({checked}) =>
                  classNames(
                    optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                    optionIdx === options.length - 2 ? 'border-b-0' : '',
                    checked
                      ? 'bg-blue-50 border-blue-200 z-10'
                      : 'border-gray-200',
                    'relative border border-t-0 border-x-0 p-8 w-full flex items-center justify-start cursor-pointer md:pl-4 md:pr-6 focus:outline-none'
                  )
                }
              >
                {({active, checked}) => (
                  <>
                    {optionsToIconsMapping[option]}
                    <div className="flex items-center justify-between flex-grow text-sm">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? 'text-blue-900' : 'text-gray-900',
                          'ml-3 font-medium'
                        )}
                      >
                        {option}
                      </RadioGroup.Label>
                      <span
                        className={classNames(
                          checked
                            ? 'bg-blue-600 border-transparent'
                            : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                          'h-4 w-4 rounded-full border flex items-center justify-center'
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>

      {selected === Options.Other && (
        <CancelReason
          reason={reason}
          setReason={setReason}
          setCanGoNext={setCanGoNext}
        />
      )}
      <button
        disabled={!canGoNext}
        ref={nextBtnRef}
        className={classNames(buttonClasses, 'mt-8')}
        onClick={onNext}
      >
        Next
      </button>
    </div>
  );
};
