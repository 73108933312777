import React from 'react';
import {Debbie} from 'components/organisms/Debbie';
import {SetupBreakdown} from 'features/setup/components/SetupBreakdown';
import {BalancedInstalmentTablePreview} from 'features/instalment-plan/components/InstalmentPreviewTable';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {capitalize, formatToDollars} from 'payble-shared';
import NumberFormat from 'react-number-format';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {getArrangementPath} from '../helpers/getArrangementPath';
import {trackSetupProgress} from '../../../analytics/hooks/useAnalytics';
import {AlternativePlans} from '../components/AlternativePlans';
import {APIOutput} from 'payble-api-client';

function PlanNotice({mode}: {mode: string}) {
  switch (mode) {
    case 'PAY_SMOOTH':
      return (
        <p className="p-4 font-light">
          <b>Important</b>: Your payments might need to be adjusted when a new
          bill is issued to ensure you are not falling behind on your payments.
          If you would like to stop payments you can cancel the plan at any
          time.
        </p>
      );
    case 'PAY_BALANCED':
    case 'PAY_EVERY_X':
      return (
        <p className="p-4 font-light">
          <b>Important</b>: Payment plans roll over to future billing periods.
          You will be notified when a new billing period begins and you can
          cancel anytime.
        </p>
      );

    default:
      return <></>;
  }
}

interface InstalmentPlanPreviewProps {
  shouldDisplayGroups: boolean;
  shouldDisplayNotice: boolean;
  balancedGroups: APIOutput<
    'consumer',
    'previewInstalmentPlan'
  >[number]['balancedInstalmentPreview'];
  onConfirm: () => void;
  onBack: () => void;
}
export const InstalmentPlanPreview: React.FC<InstalmentPlanPreviewProps> = ({
  shouldDisplayGroups,
  shouldDisplayNotice = true,
  balancedGroups,
  onConfirm,
  onBack,
}) => {
  const setupInstalments = balancedGroups.flatMap(group =>
    group.instalments.filter(i => i.type === 'setup')
  );
  const {mode, instalmentFrequency, amountInCents, instalmentMode} =
    useSetupSearchParams();
  const billerConfig = useBillerConfig();
  const navigate = useSetupNavigate();
  const arrangementPath = getArrangementPath(billerConfig);

  return (
    <>
      <Debbie title="How does this look to you?" />
      <div className="flex flex-col items-stretch w-full">
        <div className="mt-4 overflow-hidden rounded-md shadow ">
          <div className="px-4 py-4 bg-white sm:p-6">
            <div className="flex flex-col justify-center flex-1 h-full">
              {setupInstalments?.length ? (
                <SetupBreakdown setupInstalments={setupInstalments} />
              ) : null}
              <BalancedInstalmentTablePreview
                shouldDisplayGroups={shouldDisplayGroups}
                shouldShowTotal={mode !== 'PAY_SMOOTH'}
                balancedGroups={balancedGroups}
              />
              {mode === 'PAY_SMOOTH' &&
                instalmentFrequency &&
                !!amountInCents && (
                  <div className="flex py-2 text-xs font-bold sm:text-sm">
                    <div className="px-2 basis-8/12">
                      <div>
                        {capitalize(instalmentFrequency)} charge between periods
                      </div>
                    </div>
                    <div className="px-2 font-bold text-right basis-4/12">
                      <NumberFormat
                        value={formatToDollars(amountInCents)}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                      />
                    </div>
                  </div>
                )}
            </div>
            {shouldDisplayNotice && <PlanNotice mode={mode} />}
            {shouldDisplayNotice && billerConfig.calculatedInstalments ? (
              <p className="p-4 font-light">
                These payments are calculated based on your current balance.
                There may be some variances from the annual rates notice issued.
              </p>
            ) : null}
          </div>
        </div>

        {!billerConfig.catchupShowArrangementText &&
        billerConfig.alwaysOfferAlternativePlans &&
        arrangementPath ? (
          <AlternativePlans
            onClick={() => {
              navigate(arrangementPath, {
                instalmentFrequency,
              });
            }}
          />
        ) : null}

        <button
          type="button"
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => {
            onConfirm();
            trackSetupProgress('previewed_plan', {
              hasSetup: setupInstalments.length > 0,
            });
          }}
        >
          Next
        </button>
        <button
          className="mt-6 text-blue-600 transition hover:text-blue-700"
          onClick={onBack}
        >
          Back
        </button>

        {billerConfig.catchupShowArrangementText &&
        arrangementPath &&
        instalmentMode !== 'catch-up' ? (
          <p className="py-4 text-center text-blue-400 underline cursor-pointer">
            <a onClick={() => navigate(arrangementPath)}>
              Unable to make these payments?
            </a>
          </p>
        ) : null}
      </div>
    </>
  );
};
