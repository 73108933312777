import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import React from 'react';
import {useEffect, useState} from 'react';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    d => ({
      default: d.ReactQueryDevtools,
    })
  )
);
export function Devtools() {
  const [devtoolsEnabled, setDevtoolsEnabled] = useState<boolean>(
    () => !!localStorage.getItem('openDevtools')
  );

  useEffect(() => {
    //@ts-expect-error This does not exist in TS types for window
    window.openDevtools = () => {
      const newValue = localStorage.getItem('openDevtools') ? false : true;
      setDevtoolsEnabled(newValue);
      if (newValue) {
        localStorage.setItem('openDevtools', 'true');
      } else {
        localStorage.removeItem('openDevtools');
      }
    };
  }, []);

  return (
    <>
      <ReactQueryDevtools />
      {devtoolsEnabled && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
    </>
  );
}
