import {defineHTTPEndpoint} from '../utils/endpointDefinition';
import {contactResponse, contactSourceSchema} from '../schemas/contact';
import {z} from 'zod';

export const getSessionContact = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getContact',
  queryKey: () => ['user', 'contact'],
  requestSchema: null,
  responseSchema: contactResponse.nullable(),
});

export const updateContact = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'PUT /updateContact',
  requestSchema: z.object({
    givenName: z.string().optional(),
    familyName: z.string().optional(),
    title: z.string().optional(),
    email: z.string().email().optional(),
    source: contactSourceSchema.optional(),
    sendReceipt: z.boolean().optional(),
  }),
  responseSchema: contactResponse.nullable(),
});

export const sendEmailVerification = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /sendEmailVerification',
  requestSchema: null,
  responseSchema: null,
});

export const verifyEmail = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'PUT /verifyEmail',
  requestSchema: z.object({
    code: z.string(),
  }),
  responseSchema: null,
});
