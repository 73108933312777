import React, {ChangeEventHandler, useEffect, useState} from 'react';
import {CheckIcon, XMarkIcon} from '@heroicons/react/20/solid';
import {MiniSpinner} from 'components/atoms/Spinner';
import NumberFormat, {NumberFormatValues} from 'react-number-format';

import {getBankIconByName} from 'lib/banks';
import {DirectDebitValues} from 'lib/types/DirectDebit';
import {classNames} from 'lib/styles';
import {
  ACCOUNT_NAME_MAX_LENGTH,
  getUnformattedBsb,
  normaliseAccountName,
  validateBankAccountName,
} from 'payble-shared';
import {useAPIQuery} from '../../lib/api';

type Props = {
  disabled: boolean;
  onChange: (value: DirectDebitValues) => void;
  onValid: (value: boolean) => void;
  value: DirectDebitValues;
};

export const DirectDebitForm: React.FC<Props> = ({
  disabled,
  onChange,
  onValid,
  value,
}) => {
  const bsb = value.bsbNumber;

  const [accountName, setAccountName] = useState<string>(value.accountName);
  const [accountNameError, setAccountNameError] = useState<string | undefined>(
    undefined
  );

  const bsbLookup = useAPIQuery('getBankByCode', {
    data: bsb.length === 6 ? {region: 'au', code: bsb} : undefined,
    query: {
      keepPreviousData: false,
    },
  });

  const bsbStatus = (() => {
    if (bsb.length !== 6) return 'idle';
    if (bsbLookup.isFetching) return 'loading';
    if (!bsbLookup.error && bsbLookup.data === null) return 'incorrect';
    if (bsbLookup.data) return 'valid';
    return 'error';
  })();

  useEffect(() => {
    if (bsbLookup.data) {
      const icon = getBankIconByName(bsbLookup.data.name);
      onChange({
        ...value,
        bsbNumber: bsb,
        bank: {
          bsb: bsbLookup.data.code,
          name: bsbLookup.data.name,
          icon,
        },
      });
    }
  }, [bsbLookup.data]);

  const onBsbChange = async (values: NumberFormatValues) => {
    const safeBsb = getUnformattedBsb(values.formattedValue);
    if (safeBsb.length > 6) {
      return;
    }

    onChange({
      ...value,
      bsbNumber: safeBsb,
      bank: null,
    });
  };

  const onAccountNameChange: ChangeEventHandler<HTMLInputElement> = e => {
    e.target.value = normaliseAccountName(e.target.value);

    setAccountName(e.target.value.trim());
    if (!validateBankAccountName(e.target.value.trim())) {
      setAccountNameError('Invalid bank account name');
    } else {
      setAccountNameError(undefined);
    }

    onChange({
      ...value,
      accountName: e.target.value.trim(),
    });
  };

  const hasAccountName = !!value.accountName;
  const hasValidAccountNumber =
    value.accountNumber.length > 0 &&
    value.accountNumber.replaceAll('-', '').length <= 9;

  useEffect(() => {
    onValid(bsbStatus === 'valid' && hasValidAccountNumber && hasAccountName);
  }, [bsbStatus, hasValidAccountNumber, onValid, hasAccountName]);

  return (
    <>
      <div className="col-span-6">
        <label
          htmlFor="accountName"
          className="block text-sm font-bold text-navy"
        >
          Bank Account Name
        </label>
        <input
          type="text"
          name="accountName"
          id="accountName"
          disabled={disabled}
          defaultValue={accountName}
          onChange={onAccountNameChange}
          maxLength={ACCOUNT_NAME_MAX_LENGTH}
          className={classNames(
            'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
            !accountNameError ? '' : 'border-red-600'
          )}
        />
        {accountNameError && (
          <span className="mt-1 text-sm text-red-600">{accountNameError}</span>
        )}
      </div>
      <div className="relative col-span-6 sm:col-span-3">
        <label htmlFor="bsb" className="block text-sm font-bold text-navy">
          BSB number
        </label>
        <NumberFormat
          format="###-###"
          placeholder="000-000"
          name="bsb"
          id="bsb"
          className={classNames(
            'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300'
          )}
          disabled={disabled}
          allowLeadingZeros={true}
          defaultValue={bsb}
          onValueChange={(values: NumberFormatValues) => onBsbChange(values)}
        />
        <div className="absolute mt-0">
          {bsbLookup.isFetching && <MiniSpinner />}
          {bsbStatus === 'incorrect' && (
            <div className="flex items-center mt-1">
              <XMarkIcon className="w-4 h-4 text-red-500" />
              <span className="ml-1 text-sm text-gray-400">
                Unknown BSB number
              </span>
            </div>
          )}

          {bsbStatus === 'valid' && (
            <div className="flex items-center">
              <CheckIcon className="w-4 h-4 text-green-500" />
              <span className="mt-1 ml-1 text-sm text-gray-400">
                {value.bank?.name}
              </span>
              {value.bank?.icon && (
                <img src={value.bank.icon} className="w-4 ml-1" />
              )}
            </div>
          )}
          {bsbLookup.error && (
            <div className="flex items-center mt-1">
              <XMarkIcon className="w-4 h-4 text-red-500" />
              <span className="ml-1 text-sm text-gray-400">
                {bsbLookup.error.message}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="accountNumber"
          className="block text-sm font-bold text-navy"
        >
          Bank account number
        </label>
        <NumberFormat
          format="#########"
          placeholder="000000000"
          name="accountNumber"
          id="accountNumber"
          className={classNames(
            'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300'
          )}
          autoComplete={'off'}
          disabled={disabled}
          defaultValue={value.accountNumber}
          allowLeadingZeros={true}
          onValueChange={(e: NumberFormatValues) =>
            onChange({
              ...value,
              accountNumber: e.value,
            })
          }
        />

        {value.accountNumber && !hasValidAccountNumber && (
          <div className="flex items-center">
            <XMarkIcon className="w-4 h-4 text-red-500" />
            <span className="mt-1 ml-1 text-sm text-gray-400">
              Account number must be less than 10 digits
            </span>
          </div>
        )}
      </div>
    </>
  );
};
