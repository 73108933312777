import {z} from 'zod';
import * as cardInfo from '../../../util/CardInfo';
import {FormConfig} from '../../types';

export const surcharges = {
  title: 'Surcharges',
  configType: 'biller',

  validation: z.object({
    surcharges: z.array(
      z
        .object({
          type: z.enum([
            'card_brand_and_type',
            'card_brand',
            'card_type',
            'card',
          ]),
          surchargeRate: z
            .string()
            .transform(s => Number(s))
            .or(z.number())
            .pipe(z.number().min(0).max(20)),
          // These are required for the real type but we leave them as optional
          // for the API to map to the correct types
          cardType: z.enum(cardInfo.CARD_TYPE).optional(),
          cardBrand: z.enum(cardInfo.CARD_BRAND).optional(),
          fallback: z.boolean().optional(),
        })
        .refine(data => {
          if (data.type === 'card') {
            return !data.cardType && !data.cardBrand;
          } else if (data.type === 'card_brand') {
            return !data.cardType;
          } else if (data.type === 'card_type') {
            return !data.cardBrand;
          } else {
            return true;
          }
        })
    ),
  }),

  form: [
    {
      type: 'list',
      label: 'Surcharges',
      name: 'surcharges',
      addItemLabel: 'Add Surcharge',
      fields: [
        {
          type: 'dropdown',
          label: 'Surcharge Matcher',
          name: 'type',
          options: ['card_brand_and_type', 'card_brand', 'card_type', 'card'],
          description:
            'Rules are evaluated in terms of specificity. Card brand and type is the most specific and always takes priority. Card is the least specific and is only used if no other matches exist.',
        },
        {
          type: 'text',
          label: 'Rate',
          name: 'surchargeRate',
          description:
            'The surcharge rate as a percentage. 10 = 10%, 0.1 = 0.1%. At most 5 decimal places are possible',
        },
        {
          type: 'dropdown',
          label: 'Type',
          name: 'cardType',
          if: 'surcharges[context.index].type === "card_brand_and_type" || surcharges[context.index].type === "card_type"',
          options: [...cardInfo.CARD_TYPE],
        },
        {
          type: 'dropdown',
          label: 'Brand',
          name: 'cardBrand',
          if: 'surcharges[context.index].type === "card_brand_and_type" || surcharges[context.index].type === "card_brand"',
          options: [...cardInfo.CARD_BRAND],
        },
        {
          type: 'toggle',
          if: 'surcharges[context.index].type === "card"',
          label: 'Fallback',
          name: 'fallback',
          description:
            'If marked as fallback this is not presented to the user as a surcharge. It is used to make a "minimum" surcharge amount for cards that do not match (Like some random gift card)',
        },
      ],
    },
  ],
} satisfies FormConfig;
