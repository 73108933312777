import {Loading} from 'components/atoms/Loading';
import {Debbie} from 'components/organisms/Debbie';
import {TokenExCreditCardForm} from 'components/organisms/TokenExCreditCardForm';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {getBillerSlugFromUrl} from 'lib/url';
import React from 'react';
import {useBrowserBack} from 'lib/navigation/useBrowserBack';
import {useGetAuthKey} from 'lib/auth/useGetAuthKey';
import {useInfringementParams} from 'features/infringement/hooks/useInfringementParams';
import {useInfringementNavigate} from 'features/infringement/hooks/useInfringementNavigate';
import {PaymentStatus} from 'features/infringement/components/PaymentStatus';
import {useAnonymousPaymentParams} from 'features/anonymous-payment/hooks/useAnonymousPaymentParams';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useAPIMutation} from 'lib/api';
import {APIOutput} from 'payble-api-client';

type GetPaymentAmount = {
  account: NonNullable<
    APIOutput<'consumer', 'getAccountByExternalIdAnonymously'>
  >;
  amountInCents: number;
  paymentConfig: {
    lockedAmount: boolean;
    minAmount: number;
    maxAmount: number;
    defaultAmount: number;
  };
};
function getPaymentAmount({
  account,
  amountInCents,
  paymentConfig: {lockedAmount},
}: GetPaymentAmount) {
  if (account.type === 'infringements' || lockedAmount) {
    return account.amountOwing;
  }

  if (!amountInCents) {
    throw new Error(
      `AmountInCents is not set in params for anonymous payment for ${account.type} ${account.id}`
    );
  }

  return amountInCents;
}

export const PayNow: React.FC = () => {
  const {data: account, loading: loadingAccount} =
    useGetAccountFromSearchParams({anonymous: true});
  const billerSlug = getBillerSlugFromUrl();
  const {verificationCode} = useInfringementParams();

  const payAccountAnonymously = useAPIMutation('payAccountAnonymously');
  const navigate = useInfringementNavigate();
  const billerConfig = useBillerConfig();

  const {amountInCents} = useAnonymousPaymentParams();

  useBrowserBack(() => {
    account?.type === 'infringements' ? navigate('/') : null;
  });

  const {isFetching: loadingTokenEx} = useGetAuthKey({
    anonymous: true,
  });

  const loading = loadingAccount || loadingTokenEx || !account;

  if (loading) {
    return <Loading />;
  }

  const paymentConfig = billerConfig.getAccountPaymentConfig({
    ...account,
    setupBreakdown: account.setupBreakdown ?? [],
    nextInstalmentAmount: account.targetInstalments?.[0]?.amount,
  });

  const onCardComplete: React.ComponentProps<
    typeof TokenExCreditCardForm
  >['onCardComplete'] = async ({card, contact}) => {
    await payAccountAnonymously.mutateAsync({
      accountId: account.id,
      amountInCents: getPaymentAmount({
        account,
        amountInCents,
        paymentConfig,
      }),
      card,
      billerSlug,
      verificationCode,
      sendReceipt: true,
      email: contact.email,
    });
  };

  return (
    <div className="relative my-8" data-testid="paymentMethod">
      {payAccountAnonymously.data ? (
        <>
          <Debbie title="Your payment has been processed!" />
          <PaymentStatus
            account={account}
            payment={payAccountAnonymously.data}
          />
          {account.type === 'infringements' && (
            <button
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                navigate('/');
              }}
            >
              Back to home
            </button>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Debbie
                title="You’re almost there!"
                message="Enter your card details"
              />
              <div
                className="relative max-w-2xl px-4 mx-auto mt-4 sm:px-6 lg:max-w-7xl lg:px-8"
                id="payment-section"
              >
                <div className="min-w-full">
                  <TokenExCreditCardForm
                    anonymous
                    proceedButtonText="Acknowledge and pay"
                    onCardComplete={onCardComplete}
                    onCompleteError={payAccountAnonymously.error?.message}
                    disabled={loading || payAccountAnonymously.isPending}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
