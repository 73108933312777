import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';
import {addPaymentMethodResponse, paymentResponse} from '../schemas/payment';
import {contactResponse, zNZBankAccountNumber} from '../schemas';

export const getPayment = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getPayment',
  queryKey: r => ['payment', r?.paymentId],
  requestSchema: z.object({
    paymentId: z.string(),
  }),
  responseSchema: paymentResponse,
});

export const getPayments = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /getPayments',
  queryKey: () => ['user', 'payments'],
  requestSchema: null,
  responseSchema: z.array(paymentResponse),
});

export const addPaymentMethod = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /addPaymentMethod',
  requestSchema: z.union([
    z.object({
      type: z.literal('card'),
      brand: z.string(),
      expiryMonth: z.number().int().min(1).max(12),
      expiryYear: z.number().int(),
      holderName: z.string(),
      last4: z.string(),
      numberToken: z.string(),
      numberTokenHmac: z.string(),
      referenceNumber: z.string(),
      usePaymentMethodForPlans: z.array(z.string()).optional(),
    }),
    z.object({
      type: z.literal('direct_debit'),
      accountName: z.string(),
      accountNumber: z.string(),
      bsb: z.string(),
      usePaymentMethodForPlans: z.array(z.string()).optional(),
    }),
    z.object({
      type: z.literal('nz_direct_debit'),
      accountName: z.string(),
      accountNumber: zNZBankAccountNumber,
      usePaymentMethodForPlans: z.array(z.string()).optional(),
    }),
  ]),
  responseSchema: addPaymentMethodResponse,
});

export const removePaymentMethod = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'DELETE /removePaymentMethod',
  requestSchema: z.object({
    paymentMethodId: z.string(),
  }),
  responseSchema: contactResponse,
});

export const payAccountAmount = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /payAccountAmount',
  requestSchema: z.object({
    accountId: z.string(),
    paymentMethodId: z.string(),
    amountInCents: z.number(),
  }),
  responseSchema: paymentResponse,
});

export const payAccountAnonymously = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'POST /payAccountAnonymously',
  requestSchema: z.object({
    accountId: z.string(),
    amountInCents: z.number(),
    billerSlug: z.string(),
    card: z.object({
      brand: z.string(),
      expiryMonth: z.number().int().min(1).max(12),
      expiryYear: z.number().int(),
      holderName: z.string(),
      last4: z.string(),
      numberToken: z.string(),
      numberTokenHmac: z.string(),
      referenceNumber: z.string(),
    }),
    email: z.string().email().optional(),
    sendReceipt: z.boolean(),
    verificationCode: z.string().optional(),
  }),
  responseSchema: z.object({
    amount: z.number(),
  }),
});

export const getAuthKey = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /getAuthKey',
  queryKey: () => ['authKey'],
  requestSchema: null,
  responseSchema: z.object({
    authKey: z.string(),
    tokenExId: z.string(),
    origin: z.string(),
    environment: z.string(),
    timestamp: z.string(),
  }),
});

export const getAuthKeyAnonymously = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'POST /getAuthKeyAnonymously',
  queryKey: data => ['authKeyAnonymously', data],
  requestSchema: z.object({
    billerSlug: z.string(),
  }),
  responseSchema: z.object({
    authKey: z.string(),
    tokenExId: z.string(),
    origin: z.string(),
    environment: z.string(),
    timestamp: z.string(),
  }),
});
