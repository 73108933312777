import {formatToDollars} from 'payble-shared';
import {PaymentFrequencyOption} from '../shared/PaymentFrequencyOptions';
import {GetAccountByExternalIdResponse} from 'lib/account/useGetAccountFromSearchParams';
import {APIOutput} from 'payble-api-client';

type GetLabelCopy = {
  pickedPaymentIsSame: boolean;
  option: PaymentFrequencyOption;
  planFrequency: string;
  firstInstalmentAmount: number;
  firstRegularBlockInstalmentsLength: number;
  offPeriodInstalmentAmount: number;
  isPickedFrequency: boolean;
};

function getLabelCopy({
  pickedPaymentIsSame,
  option,
  firstInstalmentAmount,
  firstRegularBlockInstalmentsLength,
  offPeriodInstalmentAmount,
  planFrequency,
  isPickedFrequency,
}: GetLabelCopy) {
  const descriptionIfPaymentIsDifferent =
    isPickedFrequency && offPeriodInstalmentAmount
      ? `Then $${formatToDollars(
          offPeriodInstalmentAmount
        )} ${planFrequency} between bills.`
      : `Then your chosen amount ${planFrequency} between bills.`;

  return pickedPaymentIsSame
    ? {
        label: `$${formatToDollars(firstInstalmentAmount)} ${option.label}`,
        description:
          "If you have a bill higher than your payments coming up we'll increase your payments to ensure it is paid off in time.",
      }
    : {
        label: `${
          option.label
        } - ${firstRegularBlockInstalmentsLength} payment${
          firstRegularBlockInstalmentsLength > 1 ? 's' : ''
        } of $${formatToDollars(firstInstalmentAmount)}`,
        description: descriptionIfPaymentIsDifferent,
      };
}

type GetOptionsCopy = {
  account: GetAccountByExternalIdResponse;
  plans?: APIOutput<'consumer', 'previewInstalmentPlan'>;
  offPeriodInstalmentAmount: number;
  instalmentFrequency: string;
  frequencyOptions: PaymentFrequencyOption[];
};

export function getSmoothOptionsCopy({
  account,
  plans,
  offPeriodInstalmentAmount,
  instalmentFrequency,
  frequencyOptions,
}: GetOptionsCopy) {
  if (!plans || !account.targetInstalments?.length) {
    return frequencyOptions;
  }

  return frequencyOptions.map(option => {
    const plan = plans.find(plan => plan?.frequency === option.value);

    if (!plan) {
      return option;
    }

    const firstRegularBlock = plan.balancedInstalmentPreview.filter(
      plan => !plan.instalments.find(({type}) => type === 'setup')
    )[0];

    const firstInstalment = firstRegularBlock?.instalments[0];

    const pickedPaymentIsSame =
      firstInstalment.amount === offPeriodInstalmentAmount;

    if (!firstInstalment || !firstRegularBlock.instalments.length) {
      return option;
    }

    const isPickedFrequency = instalmentFrequency === option.value;

    const labelCopy = getLabelCopy({
      pickedPaymentIsSame,
      option,
      firstInstalmentAmount: firstInstalment.amount,
      firstRegularBlockInstalmentsLength: firstRegularBlock.instalments.length,
      offPeriodInstalmentAmount,
      planFrequency: option.label,
      isPickedFrequency,
    });

    return {
      ...option,
      ...labelCopy,
    };
  });
}
