import React from 'react';
import {useParams, Routes, Route} from 'react-router';
import {RouteName, RouteProps} from 'lib/navigation/routes';

// Pages
import {Cancel} from './pages/Cancel';
import {ChangePaymentMethod} from './pages/ChangePaymentMethod';
import {PayNextInstalment} from './pages/PayNextInstalment';
import {PayRemainder} from './pages/PayRemainder';
import {SkipInstalment} from './pages/SkipInstalment';
import {View} from './pages/View';
import {Schedule} from './pages/Schedule';
import {UpdateAmount} from './pages/UpdateAmount';
import {MaxContainer} from 'payble-ui';

const BASE_ROUTE: RouteName = '/biller/:slug/instalment-plan/:instalmentPlanId';
export const ROUTES: RouteProps[] = [
  {
    path: BASE_ROUTE,
    element: <View />,
  },
  {
    path: `${BASE_ROUTE}/cancel`,
    element: <Cancel />,
  },
  {
    path: `${BASE_ROUTE}/change-payment-method`,
    element: <ChangePaymentMethod />,
  },
  {
    path: `${BASE_ROUTE}/pay-instalment`,
    element: <PayNextInstalment />,
  },
  {
    path: `${BASE_ROUTE}/schedule`,
    element: <Schedule />,
  },
  {
    path: `${BASE_ROUTE}/pay-remainder`,
    element: <PayRemainder />,
  },
  {
    path: `${BASE_ROUTE}/skip-instalment`,
    element: <SkipInstalment />,
  },
  {
    path: `${BASE_ROUTE}/update-amount`,
    element: <UpdateAmount />,
  },
];

export const InstalmentPlan: React.FC = () => {
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();

  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  return (
    <MaxContainer>
      <Routes>
        {ROUTES.map(({path, element}) => {
          const relativePath = path.slice(BASE_ROUTE.length + 1);
          return (
            <Route key={relativePath} element={element} path={relativePath} />
          );
        })}
      </Routes>
    </MaxContainer>
  );
};
