import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';
import {
  consumerInstalmentFrequency,
  instalmentPlanResponse,
  planFrequency,
  planPayMode,
  previewInstalmentPlanResponse,
  zAbsoluteDate,
  zISODate,
} from '../schemas';

export const getInstalmentPlans = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getInstalmentPlans',
  queryKey: () => ['user', 'instalment-plans'],
  requestSchema: null,
  responseSchema: z.array(instalmentPlanResponse),
});

export const getInstalmentPlan = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getInstalmentPlan',
  queryKey: body => ['instalment-plan', body?.instalmentPlanId],
  requestSchema: z.object({
    instalmentPlanId: z.string(),
  }),
  responseSchema: instalmentPlanResponse.nullable(),
});

export const previewInstalmentPlan = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /previewInstalmentPlan',
  queryKey: data => ['preview-instalment-plan', data],
  requestSchema: z.array(
    z.object({
      accountId: z.string(),
      amount: z.number().optional(),
      frequency: consumerInstalmentFrequency,
      instalmentCount: z.number().optional(),
      offPeriodInstalmentAmount: z.number().optional(),
      payMode: planPayMode,
      startAt: zAbsoluteDate.optional(),
      targetDate: zAbsoluteDate.optional(),
    })
  ),
  responseSchema: z.array(previewInstalmentPlanResponse),
});

export const createInstalmentPlan = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /createInstalmentPlan',
  requestSchema: z.object({
    accountId: z.string(),
    amount: z.number().optional(),
    frequency: consumerInstalmentFrequency,
    instalmentCount: z.number().optional(),
    offPeriodInstalmentAmount: z.number().optional(),
    payMode: planPayMode,
    paymentMethodId: z.string(),
    startAt: zAbsoluteDate.optional(),
    targetDate: zAbsoluteDate.optional(),
  }),
  responseSchema: instalmentPlanResponse,
});

export const changeInstalmentPlanPaymentMethod = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /changeInstalmentPlanPaymentMethod',
  requestSchema: z.object({
    instalmentPlanId: z.string(),
    paymentMethodId: z.string(),
  }),
  responseSchema: instalmentPlanResponse,
});

export const payInstalment = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /payInstalment',
  requestSchema: z.object({
    instalmentPlanId: z.string(),
    instalmentId: z.string(),
    paymentMethodId: z.string().optional(),
  }),
  responseSchema: instalmentPlanResponse,
});

export const payInstalmentPlanRemainder = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /payInstalmentPlanRemainder',
  requestSchema: z.object({
    instalmentPlanId: z.string(),
    paymentMethodId: z.string().optional(),
  }),
  responseSchema: instalmentPlanResponse,
});

export const skipInstalment = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /skipInstalment',
  requestSchema: z.object({
    instalmentPlanId: z.string(),
    instalmentId: z.string(),
  }),
  responseSchema: instalmentPlanResponse,
});

export const updateInstalmentAmount = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /updateInstalmentAmount',
  requestSchema: z.object({
    instalmentPlanId: z.string(),
    amount: z.number(),
  }),
  responseSchema: instalmentPlanResponse,
});

export const cancelInstalmentPlan = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'DELETE /cancelInstalmentPlan',
  requestSchema: z.object({
    instalmentPlanId: z.string(),
    reason: z.string().optional(),
  }),
  responseSchema: instalmentPlanResponse,
});

export const createPlanRequest = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /createPlanRequest',
  requestSchema: z.object({
    accountId: z.string(),
    contactId: z.string(),
    frequency: planFrequency,
    instalmentAmount: z.number(),
    paymentMethodId: z.string(),
    startAt: zAbsoluteDate,
  }),
  responseSchema: z.object({
    id: z.string(),
    billerId: z.string(),
    accountId: z.string(),
    paymentMethodId: z.string(),
    frequency: z.string(),
    startAt: zAbsoluteDate,
    instalmentAmount: z.number(),
    contactId: z.string(),
    createdAt: zISODate,
    updatedAt: zISODate,
  }),
});
