import React, {useEffect} from 'react';
import {buttonClasses, classNames} from 'lib/styles';
import {Debbie} from 'components/organisms/Debbie';
import {useLocation} from 'react-router';
import {getBillerSlugFromUrl} from 'lib/url';
import {Loading} from 'components/atoms/Loading';
import {navigate} from 'lib/navigation/routes';
import {useEmailVerificationSend} from './useEmailVerificationSend';
import {ButtonSpinner} from '../../../components/atoms/Spinner';
import {Link} from 'react-router-dom';
import {analytics} from '../../../analytics/hooks/useAnalytics';
import {useUser} from 'lib/auth/useUser';
import {useAPIMutation} from 'lib/api';

type Props =
  | {
      state: 'LOADING';
    }
  | {
      state: 'ERROR';
      message: string;
    }
  | {
      state: 'SUCCESS';
    };

const DisplayAuthenticated: React.FC<Props> = props => {
  const slug = getBillerSlugFromUrl();

  const emailVerification = useEmailVerificationSend();

  if (props.state === 'LOADING') {
    return <Loading />;
  }

  if (props.state === 'SUCCESS') {
    return (
      <div className="flex flex-col max-w-xl md:w-xl m-auto justify-center">
        <Debbie
          title={'Thank you! Your email address has now been verified.'}
        />
        <button
          className={classNames(buttonClasses)}
          onClick={() => {
            navigate('/biller/:slug/profile', {slug});
          }}
        >
          Go to my Profile
        </button>
      </div>
    );
  }

  if (props.state === 'ERROR') {
    return (
      <>
        <div className="flex flex-col max-w-xl md:w-xl m-auto justify-center">
          <Debbie
            title={'We couldn’t verify your email address. Please try again.'}
            message={props.message}
          />
          <button
            className={buttonClasses}
            disabled={!emailVerification.send}
            onClick={emailVerification.send}
          >
            {emailVerification.state === 'LOADING' && <ButtonSpinner />}
            Send again
          </button>
          <span className="text-sm font-medium text-red-500 text-center">
            {emailVerification.errorMessage}
          </span>

          <Link
            to={`/biller/${slug}/profile/edit`}
            className="text-center mt-5"
          >
            Edit my email
          </Link>
        </div>
      </>
    );
  }

  const __: never = props;
  throw new Error('Invalid state');
};

const DisplayAnonymous: React.FC<Props> = props => {
  const slug = getBillerSlugFromUrl();

  if (props.state === 'LOADING') {
    return <Loading />;
  }

  if (props.state === 'SUCCESS') {
    return (
      <div className="flex flex-col max-w-xl md:w-xl m-auto justify-center">
        <Debbie
          title={'Thank you! Your email address has now been verified.'}
        />
        <button
          className={classNames(buttonClasses)}
          onClick={() => {
            navigate('/biller/:slug/profile', {slug});
          }}
        >
          Go to my Profile
        </button>
      </div>
    );
  }

  if (props.state === 'ERROR') {
    return (
      <div className="flex flex-col max-w-xl md:w-xl m-auto justify-center">
        <Debbie
          title={'We couldn’t verify your email address. Please try again.'}
          message={props.message}
        />
        <button
          className={classNames(buttonClasses)}
          onClick={() => {
            navigate('/biller/:slug', {slug});
          }}
        >
          Go home
        </button>
      </div>
    );
  }

  const __: never = props;
  throw new Error('Invalid state');
};

export const VerifyEmail: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const code = queryParams.get('code');

  const {
    mutateAsync: verify,
    isPending: loading,
    isSuccess: called,
    error,
  } = useAPIMutation('verifyEmail', {
    query: {
      onSuccess: () => {
        analytics.addEvent('email_verification_completed');
      },
    },
  });

  const user = useUser();

  useEffect(() => {
    if (!code) return;
    verify({code});
  }, []);

  const Component = user.contact ? DisplayAuthenticated : DisplayAnonymous;

  if (error) {
    if (error.message.includes('already verified')) {
      return <Component state="SUCCESS" />;
    }

    return <Component state="ERROR" message={error.message} />;
  }

  if (loading) {
    return <Component state="LOADING" />;
  }

  if (!called) {
    return <Component state="ERROR" message="Could not verify your email" />;
  }

  return <Component state={'SUCCESS'} />;
};
