import React, {useEffect} from 'react';
import {RouteName} from 'lib/navigation/routes';
import {Route, RouteProps, Routes, useLocation} from 'react-router-dom';
import {LookupAccountPage} from './page/LookupAccount';
import {PaymentAmountPage} from './page/PaymentAmount';
import {PayNow} from '../shared/pages/PayNow';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {NotFound} from '../navigation/NotFound';

const BASE_ROUTE: RouteName = '/biller/:slug/pay-now';

export const ROUTES: RouteProps[] = [
  {
    path: `${BASE_ROUTE}`,
    element: <LookupAccountPage />,
  },
  {
    path: `${BASE_ROUTE}/amount`,
    element: <PaymentAmountPage />,
  },
  {
    path: `${BASE_ROUTE}/pay`,
    element: <PayNow />,
  },
];

export const AnonymousPayment = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    document.title = 'Payble - Pay Now';
  }, [pathname]);

  const billerConfig = useBillerConfig();

  if (!billerConfig.features?.CONTACTLESS_PAYMENTS) {
    return (
      <div className="flex-1 h-full contents">
        <NotFound />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-start flex-1 w-full h-full overflow-hidden lg:py-8">
      <div className="z-10 flex flex-1 h-full max-w-xl m-auto md:w-xl">
        <Routes>
          {ROUTES.map(({path, element}) => {
            const relativePath = path?.slice(BASE_ROUTE.length + 1);
            return (
              <Route key={relativePath} element={element} path={relativePath} />
            );
          })}
        </Routes>
      </div>
    </div>
  );
};
