import {useAPIQuery} from 'lib/api';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';

export function useGetAuthKey({anonymous}: {anonymous?: boolean}) {
  const {billerSlug} = useBillerConfig();
  const authKey = useAPIQuery(
    anonymous ? 'getAuthKeyAnonymously' : 'getAuthKey',
    {
      data: anonymous ? {billerSlug} : undefined,
      query: {
        enabled: true,
        staleTime: 300_000,
      },
    }
  );
  return authKey;
}
