import React, {ReactNode} from 'react';
import {ErrorPage} from '../../features/navigation/Error';
import {useConsumerAppConfig} from './ConsumerAppConfig';

export const LivenessWrapper: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const {isLive} = useConsumerAppConfig();

  if (isLive) {
    return <>{children}</>;
  }

  const hostname = window.location.hostname.toLowerCase();

  // Don't allow access on exactly the production site
  if (hostname === 'app.payble.com.au') {
    return <ErrorPage />;
  }

  return <ErrorPage />;
};
