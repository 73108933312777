import React, {useEffect} from 'react';
import {Disclosure} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {HomeIcon, EnvelopeIcon} from '@heroicons/react/20/solid';
import {useLocation} from 'react-router-dom';
import cdr from 'assets/cdr.png';
import {classNames} from 'lib/styles';
import {getBillerSlugFromUrl} from 'lib/url';
import {goBack} from 'lib/navigation/routes';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {QuestionAnswer} from './components/QuestionAnswer';
import {HowToVideos} from './components/HowToVideos';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {questionAnswerData} from './data/QuestionAnswerData';
import {useConsumerAppConfig} from '../../lib/appConfig/ConsumerAppConfig';

export const Support: React.FC = () => {
  const billerConfig = useBillerConfig();
  const billerSlug = getBillerSlugFromUrl();
  const consumerAppConfig = useConsumerAppConfig();
  const cardPaymentOnly = !billerConfig.paymentMethods.includes('direct_debit');

  useEffect(() => {
    document.title = 'Payble - Support';
  }, []);

  if (!consumerAppConfig.support) {
    return (
      <ErrorMessage message={`Support is not configured for '${billerSlug}'`} />
    );
  }

  const {
    biller: {name},
    consumerAppHostname: url,
    support: {email, phone, directDebitServiceAgreement},
  } = consumerAppConfig;

  const location = useLocation();
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto pb-12 pt-4 px-4 sm:pb-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <nav className="flex mb-4" aria-label="Breadcrumb">
            <ol
              role="list"
              className="bg-white rounded-md shadow px-6 flex space-x-4"
            >
              <li className="flex">
                <div className="flex items-center">
                  <a
                    onClick={goBack}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    Support
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <div>
            <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
              We're always here to help
            </h2>
            <h2 className="text-center text-1xl font-extrabold text-gray-700 sm:text-2xl">
              Just let us know what you need?
            </h2>
          </div>
          <dl
            className="mt-6 space-y-6 divide-y divide-gray-200"
            key={location.hash}
          >
            <HowToVideos />

            <Disclosure
              as="div"
              className="pt-6"
              defaultOpen={location.hash.includes('payble-contact')}
            >
              {({open}) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        I need help setting up my payments
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(
                            open ? '-rotate-180' : 'rotate-0',
                            'h-6 w-6 transform'
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-2">
                    <p className="text-base text-gray-500">
                      {`That’s not good! Please contact ${phone} or ${email} for support.`}
                      <a
                        type="button"
                        target="_blank"
                        href={`mailto:${email}?subject=Question about the app&body=Dear Payble,I have a question about the app...`}
                        className="flex justify-center  m-auto items-center my-2 px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Email Council
                        <EnvelopeIcon className="ml-2 text-gray-300 w-6" />
                      </a>
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure
              as="div"
              className="pt-6"
              defaultOpen={location.hash.includes('about')}
            >
              {({open}) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        Who is Payble? What do they do?
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(
                            open ? '-rotate-180' : 'rotate-0',
                            'h-6 w-6 transform'
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-2">
                    <p className="text-base text-gray-500">
                      Payble is the safe and easy way to pay and manage your
                      bills. With Payble you can easily pay and manage your
                      bills, and activate flexible payment options if you need.
                      We’re partnering with innovative businesses around
                      Australia to improve how bills are paid.
                      <a
                        href="https://www.cdr.gov.au/find-a-provider?provider=ADRBNK2004"
                        target={'_blank'}
                      >
                        <img
                          src={cdr}
                          className="rounded-lg w-72 m-auto my-2"
                        />
                      </a>
                      You can rest assured that all payment and personal
                      information is stored safely and meets the highest
                      requirements for data security. In fact, Payble is
                      approved by the ACCC under the Consumer Data Right.
                      <a
                        type="button"
                        href={`#payble-contact?t=${Date.now()}`}
                        className="flex justify-center m-auto items-center my-2 px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Contact Payble
                      </a>
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {!cardPaymentOnly && (
              <Disclosure
                as="div"
                className="pt-6"
                defaultOpen={location.hash.includes('ddrsa')}
              >
                {({open}) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          Where can I find my Direct Debit Service Agreement?
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-2">
                      <p className="text-base text-gray-500">
                        You can find the Direct Debit Service Agreement for{' '}
                        {name} at the following link:
                        <a
                          href={directDebitServiceAgreement}
                          target={'_blank'}
                          className="flex justify-center m-auto items-center my-2 px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Direct Debit Service Agreement (DDRSA)
                        </a>
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}
            <Disclosure
              as="div"
              className="pt-6"
              defaultOpen={location.hash.includes('terms')}
            >
              {({open}) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        Can I access the Payble terms and conditions?
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(
                            open ? '-rotate-180' : 'rotate-0',
                            'h-6 w-6 transform'
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-2">
                    <p className="text-base text-gray-500">
                      Yes. Terms and conditions are always available online and
                      can be accessed here:{' '}
                      <a
                        href="https://payble.com.au/privacy-terms"
                        target={'_blank'}
                        className="font-medium text-gray-900"
                      >
                        Payble Terms and Privacy
                      </a>{' '}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {/* Appending all the simple FAQs at the bottom */}
            {!!email &&
              !!phone &&
              questionAnswerData({
                billerContact: {name, email, phone, url},
                billerSlug,
              }).map(qa => (
                <QuestionAnswer
                  question={qa.question}
                  answer={qa.answer}
                  key={qa.question}
                />
              ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
