/**
 * DO NOT ADD TO THIS FILE.
 *
 * These helpers are an anti-pattern and should be removed.
 *
 * They are only added to assist in the migration to the standard config
 * interface from the previous "payble-biller-config" package.
 */
import {BillerConfig, getBillerConfig} from '..';
import {
  CreditCardGateway,
  DirectDebitGateway,
  FeatureConfig,
  NZDirectDebitGateway,
  PaymentMethodType,
} from '../schema';

export type GatewayForType<T extends PaymentMethodType> = {
  direct_debit: DirectDebitGateway;
  card: CreditCardGateway;
  nz_direct_debit: NZDirectDebitGateway;
}[T];

/**
 * @deprecated
 */
export const getBillerGateway = <T extends PaymentMethodType>(args: {
  paymentMethodType: T;
  billerConfig: BillerConfig;
}): GatewayForType<T> | Error => {
  return args.billerConfig.gatewayForPaymentMethod(args.paymentMethodType);
};

/**
 * @deprecated
 */
export const getBillerAutoPayTargetDates = (billerSlug: string) => {
  return getBillerConfig(billerSlug).autopayTargetDates;
};

/**
 * @deprecated
 */
export const getAccountExternalIdLabel = (
  slug: string,
  accountType: string
): string => {
  return getBillerConfig(slug).getExternalIdLabel(accountType);
};

/**
 * @deprecated
 */
export const getFeatureConfig = (slug: string): FeatureConfig => {
  return getBillerConfig(slug).features;
};

/**
 * @deprecated
 */
export const getYearlyRollOverStatusByBillerSlug = (slug: string): boolean => {
  return getBillerConfig(slug).hasYearlyRollOver;
};
