import React from 'react';
import NumberFormat from 'react-number-format';
import {formatToDollars} from 'payble-shared';
import {APIOutput} from 'payble-api-client';

export const ConfirmationPaymentAmountSection = ({
  mode,
  instalmentPlan,
  amountInCents,
}: {
  mode: string;
  instalmentPlan?: APIOutput<'consumer', 'createInstalmentPlan'>;
  amountInCents?: number;
}) => {
  if (mode === 'PAY_AUTO') {
    return (
      <>
        <p className="text-sm text-gray-600">Payment amount</p>
        <p className="text-sm text-gray-600">Check your notice</p>
      </>
    );
  }

  if (mode === 'PAY_EVERY_X' && instalmentPlan?.instalments[0]?.amount) {
    return (
      <>
        <p className="text-sm text-gray-600">Payment amount</p>
        <p className="text-sm text-gray-600">
          <NumberFormat
            value={formatToDollars(instalmentPlan.instalments[0].amount)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </p>
      </>
    );
  }

  if (amountInCents) {
    return (
      <>
        <p className="text-sm text-gray-600">Payment amount</p>
        <p className="text-sm text-gray-600">
          <NumberFormat
            value={formatToDollars(amountInCents)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </p>
      </>
    );
  }
  return null;
};
