import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import storage from 'local-storage-fallback';

// Using Native app to avoid redirect

const JWT_KEY = 'jwt_auth';

type Token = {
  exp: number;
  billerId: string;
  contactId: string;
};

type Credentials = {
  id_token: string;
};

export function validate() {
  const credentials = loadCredentials();

  if (!credentials) {
    throw new Error('Token does not exist');
  }

  const token: Token = jwtDecode(credentials.id_token);
  const hasExpired = !token || token.exp * 1000 < Date.now();

  if (hasExpired) {
    throw new Error('Token has expired');
  }
}

export function safeValidate() {
  try {
    validate();
  } catch (error) {
    return false;
  }

  return true;
}

export function storeCredentials(credentials: Credentials) {
  storage.setItem(JWT_KEY, JSON.stringify(credentials));
}

export function loadCredentials() {
  const credentials = storage.getItem(JWT_KEY);

  if (!credentials) {
    return null;
  }

  try {
    const parsed: Credentials = JSON.parse(credentials);
    return parsed;
  } catch (error) {
    console.error('Unable to parse JWT');
    Sentry.captureException(error);
    clear();
    return null;
  }
}

export function clear() {
  storage.removeItem(JWT_KEY);
}
