import {AbsoluteDate} from 'payble-shared';
import {APIInput, APIOutput} from 'payble-api-client';

type PreviewInstalmentPlanInput = APIInput<'consumer', 'previewInstalmentPlan'>;
type PreviewInstalmentPlanOutput = APIOutput<
  'consumer',
  'previewInstalmentPlan'
>;

type GetPlans = {
  startDate: AbsoluteDate;
  accountId: string;
  payMode: PreviewInstalmentPlanInput[number]['payMode'];
  targetDate?: AbsoluteDate;
  offPeriodInstalmentAmount?: number;
};

export function getPlanFrequencyInputs({
  startDate,
  accountId,
  payMode,
  targetDate,
  offPeriodInstalmentAmount,
}: GetPlans): NonNullable<PreviewInstalmentPlanInput> {
  const commonPlanProps = {
    startAt: startDate,
    accountId,
    payMode,
    ...(targetDate ? {targetDate: targetDate} : {}),
    offPeriodInstalmentAmount: offPeriodInstalmentAmount ?? 0, // TODO: ivan check difference between 0 and undefined for offPeriodInstalmentAmount
    // ...(offPeriodInstalmentAmount ? {offPeriodInstalmentAmount} : {}),
  };

  return [
    {
      ...commonPlanProps,
      frequency: 'weekly',
    },
    {
      ...commonPlanProps,
      frequency: 'fortnightly',
    },
    {
      ...commonPlanProps,
      frequency: 'monthly',
    },
  ];
}

type BalancedInstalmentPreviewGroup =
  PreviewInstalmentPlanOutput[number]['balancedInstalmentPreview'];

function getSummarisedInstalments(
  planToPreview: BalancedInstalmentPreviewGroup[number]
) {
  return planToPreview.instalments.reduce(
    (acc, curr, index) => {
      const amount = curr.amount;
      const existingEntry = acc.find(entry => entry.amount === amount);

      if (existingEntry) {
        existingEntry.instalmentIndex.push(index);
      } else {
        acc.push({amount, instalmentIndex: [index]});
      }
      return acc;
    },
    [] as {amount: number; instalmentIndex: number[]}[]
  );
}

type InstalmentPlanResult = {
  amountPerInstalment: number;
  instalmentCount: number;
  frequency: NonNullable<PreviewInstalmentPlanOutput[number]['frequency']>;
  remainderAmount?: number;
};

type ToPlanSummary = {
  balancedInstalmentPreview: BalancedInstalmentPreviewGroup;
  onError: (e: unknown) => void;
  frequency?: PreviewInstalmentPlanOutput[number]['frequency'];
};
export function toPlanSummary({
  balancedInstalmentPreview,
  onError,
  frequency,
}: ToPlanSummary): InstalmentPlanResult | undefined {
  if (balancedInstalmentPreview.length !== 1) {
    onError(
      new Error(
        `Expected 1 plan preview, got ${balancedInstalmentPreview.length}`
      )
    );
    return undefined;
  }
  const planToPreview = balancedInstalmentPreview[0];

  const summarisedInstalments = getSummarisedInstalments(planToPreview);

  if (summarisedInstalments.length <= 0 || summarisedInstalments.length > 2) {
    onError(
      new Error(`Unable to map ${summarisedInstalments.length} instalments`)
    );
    return undefined;
  }
  const twoPayments = summarisedInstalments.every(
    ({instalmentIndex}) => instalmentIndex.length === 1
  );
  const remainder = twoPayments
    ? summarisedInstalments[1]
    : summarisedInstalments.find(
        ({instalmentIndex}) => instalmentIndex.length === 1
      );

  const regularInstalments = twoPayments
    ? summarisedInstalments[0]
    : summarisedInstalments.find(({instalmentIndex}) => {
        return instalmentIndex.length > 1;
      });

  if (!regularInstalments || !frequency) {
    onError(
      new Error(
        `Unable to map regular instalments or frequency regularInstalments: ${
          regularInstalments
            ? Object.entries(regularInstalments)
            : regularInstalments
        } frequency: ${frequency}`
      )
    );

    return undefined;
  }

  return {
    frequency: frequency,
    amountPerInstalment: regularInstalments.amount,
    remainderAmount: remainder?.amount ?? undefined,
    instalmentCount: regularInstalments.instalmentIndex.length,
  };
}
