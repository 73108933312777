import {z} from 'zod';
import {FormConfig} from '../types';

const accountSyncStrategy = z.enum([
  'T1_WATER_DATE_PATCH',
  'PATHWAY_V2',
  'PATHWAY',
  'CESSNOCK',
  'T1_DEBTOR_EXPORT',
  'T1_PROPERTY_RATES',
  'T1_PROPERTY_PATCH',
  'T1_WATER_RATES',
  'PRACTICAL_RATES',
  'PRACTICAL_WATER_RATES',
  'PRACTICAL_REFERENCE_RATES',
  'PRACTICAL_REFERENCE_WATER_RATES',
  'INFRINGEMENTS_V1',
  'FORMS_EXPRESS',
  'FORMS_EXPRESS_V2',
  'RATES_EXTRACT',
  'BEGA_WATER_EXTRACT',
  'BEGA_RATES',
  'NILLUMBIK',
  'READYTECH_V1',
]);

const accountSyncDefaults = z
  .object({
    accountType: z.string(),
    archiveMissing: z.boolean().optional(),
    sendOwnershipChangeNotifications: z.boolean().optional(),
    ignoreParserProtection: z.boolean().optional(),
    autoImportFromSftp: z.boolean().optional(),
    autoImportWhenNonAdminsUpload: z.boolean().optional(),
  })
  .optional();

export const baseSchema = z.object({
  autoImportConfigId: z.string().min(1).optional(),
  filenameMatcher: z
    .string()
    .min(3)
    .refine(
      value => {
        const disallowedFilenameMatcherCharacters = [
          '?',
          '+',
          '(',
          ')',
          '[',
          ']',
          '{',
          '}',
          '|',
          '^',
          '$',
        ];
        for (let i = 0; i < value.length; i++) {
          if (value[i] === '*' && (i === 0 || value[i - 1] !== '.')) {
            return false;
          }

          if (disallowedFilenameMatcherCharacters.includes(value[i])) {
            return false;
          }
        }
        return true;
      },
      {message: 'Invalid pattern: Use .* instead of *'}
    ),
});

const accountSyncSchema = baseSchema.extend({
  processType: z.literal('accountSync'),
  strategy: accountSyncStrategy,
  defaults: accountSyncDefaults,
});

const payerSyncStrategy = z.enum(['UNIVERSAL_PAYER_IMPORT']);

const payerSyncSchema = baseSchema.extend({
  processType: z.literal('payerImport'),
  strategy: payerSyncStrategy,
});

export const autoImportConfigSchema = z.discriminatedUnion('processType', [
  accountSyncSchema,
  payerSyncSchema,
]);

export const optionsSchema = z.array(
  z
    .object({
      value: z.string().optional(),
      label: z.string().optional(),
    })
    .or(z.string())
);

const strategyDefaultFields = [
  {
    type: 'text',
    name: 'accountType',
    label: 'Account type',
  },
  {
    type: 'toggle',
    name: 'archiveMissing',
    label: 'Archive missing',
  },
  {
    type: 'toggle',
    name: 'sendOwnershipChangeNotifications',
    label: 'Send ownership change notifications',
  },
  {
    type: 'toggle',
    name: 'ignoreParserProtection',
    label: 'Ignore parser protection',
  },
  {
    type: 'toggle',
    name: 'autoImportFromSftp',
    label: 'Auto import from SFTP',
  },
  {
    type: 'toggle',
    name: 'autoImportWhenNonAdminsUpload',
    label: 'Auto import when non-admins upload',
  },
];

export const autoImportsFormConfig = {
  validation: autoImportConfigSchema,

  layout: {
    columns: 2,
  },

  context: {
    processTypeStrategyOptions: {
      accountSync: [
        {value: ''},
        ...accountSyncStrategy.options.map(value => ({value})),
      ],
      payerImport: [...payerSyncStrategy.options.map(value => ({value}))],
    },
    strategyDefaultFields: {
      accountSync: {
        PATHWAY_V2: strategyDefaultFields,
        PATHWAY: strategyDefaultFields,
        CESSNOCK: strategyDefaultFields,
        T1_DEBTOR_EXPORT: strategyDefaultFields,
        T1_PROPERTY_RATES: strategyDefaultFields,
        PRACTICAL_RATES: strategyDefaultFields,
        PRACTICAL_WATER_RATES: strategyDefaultFields,
        PRACTICAL_REFERENCE_RATES: strategyDefaultFields,
        PRACTICAL_REFERENCE_WATER_RATES: strategyDefaultFields,
        INFRINGEMENTS_V1: strategyDefaultFields,
        FORMS_EXPRESS: strategyDefaultFields,
        FORMS_EXPRESS_V2: strategyDefaultFields,
        RATES_EXTRACT: strategyDefaultFields,
        BEGA_WATER_EXTRACT: strategyDefaultFields,
        BEGA_RATES: strategyDefaultFields,
        NILLUMBIK: strategyDefaultFields,
        READYTECH_V1: strategyDefaultFields,
      },
    },
  },

  form: [
    {
      type: 'text',
      name: 'filenameMatcher',
      label: 'Filename matcher',
      placeholder: 'pathway_rnrv2_*',
      description:
        'Use .* to pattern match filenames (e.g., .*pathway_rnrv2_.* will match sep_pathway_rnrv2_2024.csv)',
    },
    {
      type: 'dropdown',
      name: 'processType',
      label: 'Process',
      options: [
        {value: '', label: ''},
        {value: 'accountSync', label: 'Account sync'},
        {value: 'payerImport', label: 'Payer sync'},
        {value: 'contactPlanCreation', label: 'Contact plan creation'},
        {value: 'bulkSmsDelivery', label: 'Bulk SMS delivery'},
      ],
    },
    {
      type: 'dropdown',
      name: 'strategy',
      label: 'Strategy',
      if: '!!processType',
      options: 'context.processTypeStrategyOptions[processType]',
    },
    {
      type: 'fieldset',
      label: 'Import defaults',
      name: 'defaults',
      if: '!!strategy',
      fields: 'context.strategyDefaultFields[processType][strategy]',
    },
  ],
} satisfies FormConfig;
