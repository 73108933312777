import {ConsumerAppConfig} from 'payble-shared/src/app-config/ConsumerAppConfig';
import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';

export const getConsumerAppConfig = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'GET /app-config',
  queryKey: () => ['app-config'],
  requestSchema: z.object({
    // The complete URL of the site including path, query, hash, protocol, etc.
    url: z.string(),
  }),
  responseSchema: ConsumerAppConfig.schema,
});
