import {navigate} from 'lib/navigation/routes';
import {getBillerSlugFromUrl} from 'lib/url';
import {
  GetAccountByExternalIdResponse,
  useGetAccountFromSearchParams,
} from '../../../lib/account/useGetAccountFromSearchParams';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import {Loading} from 'components/atoms/Loading';
import {
  ContactResponse,
  PaymentMethodResponse,
} from 'payble-api-client/schemas';
import {APIOutput} from 'payble-api-client';

type CompletedState = {
  mode: string;
  contact: ContactResponse;
  payment?: APIOutput<'consumer', 'payAccountAmount'>;
  instalmentPlan?: APIOutput<'consumer', 'createInstalmentPlan'>;
  planRequest?: APIOutput<'consumer', 'createPlanRequest'>;
  paymentMethod: PaymentMethodResponse;
};
type SetupRouteValue = {
  account: GetAccountByExternalIdResponse;
  completedState?: CompletedState;
  setCompletedState: Dispatch<SetStateAction<CompletedState | undefined>>;
};
const SetupRouteContext = createContext<SetupRouteValue>({} as SetupRouteValue);

export const useSetupRoute = () => useContext(SetupRouteContext);

export const SetupRoute = ({children}: {children: ReactNode}) => {
  const {loading, data: account, error} = useGetAccountFromSearchParams();
  const [completedState, setCompletedState] = useState<CompletedState>();

  if (loading) return <Loading />;

  if (!account || error) {
    navigate('/biller/:slug', {
      slug: getBillerSlugFromUrl(),
    });

    return null;
  }

  return (
    <SetupRouteContext.Provider
      value={{account, completedState, setCompletedState}}
    >
      {children}
    </SetupRouteContext.Provider>
  );
};
