import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';

export const getBankByCode = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getBankByCode',
  queryKey: e => ['bank-code', e?.code],
  requestSchema: z.object({code: z.string(), region: z.string()}),
  responseSchema: z
    .object({
      code: z.string(),
      name: z.string(),
    })
    .nullable(),
});
