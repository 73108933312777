import {useState, useEffect, ReactNode} from 'react';
import {
  useBillerConfig,
  usePaymentSurchargeInfo,
} from 'lib/appConfig/useBillerConfig';
import * as Sentry from '@sentry/react';
import {TermsModal} from 'components/molecules/TermsModal';
import {getBillerFriendlyNameFromSlug} from 'lib/url';
import {SurchargeAgreementModal} from 'components/molecules/SurchargeAgreementModal';
import {StyledCheckbox} from 'features/setup/components/StyledCheckbox';
import {PaymentMethodType} from 'payble-shared';
import {useConsumerAppConfig} from '../../lib/appConfig/ConsumerAppConfig';

type Props = {
  paymentMethodType: PaymentMethodType;
  callback: (isChecked: boolean) => void;
  disabled?: boolean;
};

type Toggles = Record<string, boolean>;

export const PaymentMethodAgreement = ({
  paymentMethodType,
  callback,
  disabled,
}: Props) => {
  const toggles = usePaymentMethodAgreementToggles(paymentMethodType);

  const [toggleStates, setToggleStates] = useState<Toggles>(
    toggles.reduce(
      (acc, {name, checked, children}) => ({...acc, children, [name]: checked}),
      {}
    )
  );

  const onToggle = (name: keyof Toggles) => {
    setToggleStates(prev => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  useEffect(() => {
    callback(Object.values(toggleStates).every(Boolean));
  }, [toggleStates]);

  return (
    <div className="w-full col-span-6">
      {toggles.map(({name, children}) => (
        <StyledCheckbox
          name={name}
          checked={toggleStates[name]}
          onClick={() => onToggle(name)}
          id={name}
          disabled={disabled}
          key={name}
        >
          {children}
        </StyledCheckbox>
      ))}
    </div>
  );
};

function usePaymentMethodAgreementToggles(
  paymentMethodType: PaymentMethodType
): {
  name: string;
  checked: boolean;
  children: ReactNode;
}[] {
  const billerConfig = useBillerConfig();
  const consumerAppConfig = useConsumerAppConfig();
  const becsUserId = consumerAppConfig.directDebitUserId;
  const billerName = getBillerFriendlyNameFromSlug(billerConfig.billerSlug);
  const [surchargeModalOpen, setSurchargeModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [directDebitModalOpen, setDirectDebitModalOpen] = useState(false);
  const [directDebitSoleOwnerModalOpen, setDirectDebitSoleOwnerModalOpen] =
    useState(false);

  useEffect(() => {
    if (!becsUserId && billerConfig.paymentMethods.includes('direct_debit')) {
      Sentry.captureException(
        `${billerName} is missing a direct debit user ID.`
      );
    }
  }, [becsUserId]);

  const surcharge = usePaymentSurchargeInfo(paymentMethodType);

  const paymentNotificationConsent =
    consumerAppConfig.requirePaymentNotificationConsent
      ? `, and I/we agree to receive payment notifications and other
                  essential communications from ${consumerAppConfig.biller.name} via text
                  message.`
      : null;

  const toggles = [
    paymentMethodType === 'nz_direct_debit'
      ? {
          name: 'nz-dd-privacy-policy-agreement',
          checked: false,
          children: (
            <>
              <p>
                I/we agree to{' '}
                <a
                  className="text-blue-600 underline cursor-pointer"
                  onClick={() => setDirectDebitModalOpen(true)}
                >
                  {consumerAppConfig.biller.name} and Payble terms and
                  conditions
                </a>
                {paymentNotificationConsent}
              </p>
              <TermsModal
                isOpen={directDebitModalOpen}
                setOpen={setDirectDebitModalOpen}
                terms={[
                  `I confirm that I have read and accept the <u>[direct debit terms and conditions](${consumerAppConfig.support.directDebitServiceAgreement})</u>`,
                  'I Confirm that the direct debit is also subject to my banks’ standard terms and conditions that relate to my account',
                  'I agree to receive confirmation of the sign up to direct debits (within 5 business <u>days</u>) and <u>notifications</u> for ongoing payments to the email address provided',
                  `I agree to ${consumerAppConfig.biller.name}’s <u>[Privacy Policy](https://hamilton.govt.nz/privacy-policy)</u>`,
                ]}
              />
            </>
          ),
        }
      : {
          name: 'privacy-policy-agreement',
          checked: false,
          children: (
            <>
              I/we agree to Payble's{' '}
              <a
                href="https://payble.com.au/terms-privacy/"
                className="text-blue-600 underline"
                target="_blank"
              >
                Terms and Conditions and Privacy Policy
              </a>
              {paymentNotificationConsent}
            </>
          ),
        },
  ];

  if (paymentMethodType === 'direct_debit') {
    toggles.push({
      name: 'becs-service-agreement',
      checked: false,
      children: (
        <>
          I/we authorise and request, {billerName} (User ID {becsUserId}) to
          debit the account provided through the Bulk Electronic Clearing System
          (BECS) in accordance with the{' '}
          <a
            href={consumerAppConfig.support?.directDebitServiceAgreement}
            className="text-blue-600 underline"
            target="_blank"
          >
            Direct Debit Request Service Agreement
          </a>
        </>
      ),
    });
  }
  if (billerConfig.billerSlug === 'cessnock-city-council') {
    if (paymentMethodType === 'direct_debit') {
      toggles.push({
        name: 'information-consent-agreement',
        checked: false,
        children: (
          <>
            I/we acknowledge I have read, understand and consent for my
            information to be collected, used, disclosed or otherwise handled as
            outlined above. I acknowledge the information I provided is
            truthful, accurate and complete
          </>
        ),
      });
    }
    toggles.push({
      name: 'information-collection-agreement',
      checked: false,
      children: (
        <>
          I/we consent for my information to be collected, used, disclosed or
          otherwise handled by Council for the purpose of:
          <ul>
            <li>
              sharing information with me concerning Council services,
              functions, operations and events;
            </li>
            <li>
              contacting me to seek voluntary completion of surveys that relate
              to Council’s services, functions and operations.
            </li>
          </ul>
        </>
      ),
    });
  }

  if (billerConfig.billerSlug === 'energy-on') {
    toggles.push({
      checked: false,
      name: 'confirm-dd-surcharge',
      children: (
        <>
          I understand that dishonour fees may apply if my direct debit payment
          fails.
        </>
      ),
    });
  }

  if (
    paymentMethodType === 'direct_debit' &&
    billerConfig.requiresNominatedAccountAgreement
  ) {
    toggles.push({
      checked: false,
      name: 'confirm-nominated-account-agreement',
      children: (
        <p>
          {billerConfig.allowAuthorityToOperateNominatedAccount
            ? 'I confirm that I have authority on the nominated bank account to establish this direct debit.'
            : 'I confirm the nominated bank account is solely in my name, and is not a joint account or business account.'}{' '}
          {!!billerConfig.nominatedAccountAgreementTerms && (
            <>
              <a
                className="text-blue-600 underline cursor-pointer"
                onClick={() => setTermsModalOpen(true)}
              >
                {billerConfig.allowAuthorityToOperateNominatedAccount
                  ? 'Why am I confirming this?'
                  : 'Why?'}
              </a>
              <TermsModal
                isOpen={termsModalOpen}
                setOpen={setTermsModalOpen}
                terms={billerConfig.nominatedAccountAgreementTerms}
              />
            </>
          )}
        </p>
      ),
    });
  }
  if (surcharge.type !== 'NONE') {
    toggles.push({
      checked: false,
      name: 'surcharge-agreement',
      children: (
        <>
          <p>
            {surcharge.agreement}
            {surcharge.type === 'COMPLEX' && (
              <>
                {' '}
                <a
                  className="text-blue-600 underline cursor-pointer"
                  onClick={() => setSurchargeModalOpen(true)}
                >
                  Surcharges.
                </a>
                <SurchargeAgreementModal
                  isOpen={surchargeModalOpen}
                  setOpen={setSurchargeModalOpen}
                  surcharge={surcharge.rateInfo}
                />
              </>
            )}
          </p>
        </>
      ),
    });
  }

  if (paymentMethodType === 'nz_direct_debit') {
    toggles.push({
      checked: false,
      name: 'nz-dd-confirm-sole-owner-account-agreement',
      children: (
        <>
          I confirm that I have sole authority on the nominated account.{' '}
          <a
            className="text-blue-600 underline cursor-pointer"
            onClick={() => setDirectDebitSoleOwnerModalOpen(true)}
          >
            Why?
          </a>
          <TermsModal
            isOpen={directDebitSoleOwnerModalOpen}
            setOpen={setDirectDebitSoleOwnerModalOpen}
            terms={[
              `If more than one signature is required, complete and sign the Bank account <u>[direct debit form](${consumerAppConfig.support.directDebitServicePaperForm})</u> and post to the address provided or scan and email to <u>[rates@hcc.govt.nz](mailto:rates@hcc.govt.nz)</u>.`,
              'Alternatively to continue online, go back and change your payment option to ‘Credit/debit card’, or nominate an account that is not held jointly.',
            ]}
          />
        </>
      ),
    });
  }

  return toggles;
}
