import React, {useState, useEffect} from 'react';
import * as Sentry from '@sentry/react';
import NumberFormat from 'react-number-format';
import 'react-datepicker/dist/react-datepicker.css';
import {getBillerSlugFromUrl} from 'lib/url';
import {Debbie} from 'components/organisms/Debbie';
import {goBack, navigate as navigateTo} from 'lib/navigation/routes';
import {Loading} from 'components/atoms/Loading';
import {classNames} from 'lib/styles';
import {TryAgain} from 'components/molecules/TryAgain';
import {RolloverNotice} from 'components/atoms/RolloverNotice';
import {getPlanFrequencyInputs, toPlanSummary} from 'lib/getPlans';
import './styles.css';
import {StyledRadioGroup} from 'features/setup/components/StyledRadioGroup';
import {AbsoluteDate, formatToDollars} from 'payble-shared';
import {useSetupRoute} from '../components/SetupRoute';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {usePatchSearchParams} from '../../../lib/navigation/usePatchSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {NoPlanOptions} from '../components/NoPlanOptions';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {
  getFeatureConfig,
  getYearlyRollOverStatusByBillerSlug,
} from 'payble-shared/src/biller-config/handlers';
import {getArrangementPath} from '../helpers/getArrangementPath';
import {DatePicker} from '../shared/DatePicker';
import {AlternativePlans} from '../components/AlternativePlans';
import {useAPIQuery} from 'lib/api';
import {APIInput} from 'payble-api-client';

export const FlexiblePayEveryX: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {account} = useSetupRoute();
  const navigate = useSetupNavigate();
  const {patch} = usePatchSearchParams();
  const {instalmentStartAt} = useSetupSearchParams();
  const billerConfig = useBillerConfig();
  const hasYearlyRollOver = getYearlyRollOverStatusByBillerSlug(billerSlug);
  const [frequency, setFrequency] =
    useState<
      APIInput<'consumer', 'previewInstalmentPlan'>[number]['frequency']
    >('weekly');

  const accountHasArrears =
    (account.setupBreakdown?.reduce(
      (sum, b) => sum + Math.max(b.amount, 0),
      0
    ) ?? 0) > 0;

  const arrangementPath = getArrangementPath(billerConfig);

  const [targetDate, setTargetDate] = useState<Date | undefined>(undefined);

  const [previewInstalmentPlanInput, setPreviewInstalmentPlanInput] =
    useState<APIInput<'consumer', 'previewInstalmentPlan'>>();

  const {
    data: previewInstalmentPlan,
    error,
    isFetching: loading,
  } = useAPIQuery('previewInstalmentPlan', {
    data: previewInstalmentPlanInput ?? undefined,
  });

  useEffect(() => {
    if (error) {
      Sentry.captureException(
        new Error(
          `Could not load instalment preview: biller: ${
            billerSlug ?? 'slug missing'
          } ${error.message}`
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!targetDate && account.targetDate) {
      setTargetDate(new Date(account.targetDate.toISO()));
    }
  }, [account.targetDate]);

  useEffect(() => {
    const fetchPlans = async () => {
      if (!instalmentStartAt || !targetDate) {
        return null;
      }

      setPreviewInstalmentPlanInput(
        getPlanFrequencyInputs({
          startDate: instalmentStartAt,
          accountId: account.id,
          payMode: 'pay-every-x',
        })
      );
    };
    if (!previewInstalmentPlan?.length && !loading && !error) {
      fetchPlans();
    }
  }, [targetDate, instalmentStartAt]);

  if (account.amountOwing <= 0) {
    return (
      <TryAgain
        errorMessage="No amount owing"
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
        buttonText={'Go Back'}
      />
    );
  }

  const today = AbsoluteDate.today({billerConfig});

  const [startDate, setStartDate] = useState<AbsoluteDate | null>(
    instalmentStartAt
  );

  const endDate = today.plus({
    days: getFeatureConfig(billerSlug).FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS,
  });
  const [dateError, setDateError] = useState<Error | undefined>(undefined);

  if (!targetDate) {
    return <Loading />;
  }

  const onSetStartDate = (date: AbsoluteDate | null) => {
    setDateError(undefined);

    const minStartDate = today;
    const maxEndDate = endDate;
    if (date === null) {
      setDateError(new Error('Start date is required'));
      return;
    }
    if (date.isBefore(minStartDate)) {
      setDateError(
        new Error(
          `Start date cannot be before ${minStartDate.toFormat('dd/MM/yyyy')}`
        )
      );
      return;
    }
    if (date.isAfter(maxEndDate)) {
      setDateError(
        new Error(
          `Start date cannot be after ${maxEndDate.toFormat('dd/MM/yyyy')}`
        )
      );
      return;
    }

    patch({instalmentStartAt: date.toISO()}, {replace: true});
    setStartDate(date);

    setPreviewInstalmentPlanInput(
      getPlanFrequencyInputs({
        startDate: date,
        accountId: account.id,
        payMode: 'pay-every-x',
      })
    );
  };

  const plans = previewInstalmentPlan
    ?.map(plan => {
      if (!plan || !plan.frequency) {
        return undefined;
      }

      return toPlanSummary({
        balancedInstalmentPreview: plan.balancedInstalmentPreview,
        frequency: plan.frequency,
        onError: Sentry.captureException,
      });
    })
    .filter((plan): plan is NonNullable<typeof plan> => plan !== undefined);

  return (
    <div className="relative" data-testid="payEveryX">
      <div className="relative flex flex-col h-full">
        <Debbie
          title="Great, which plan suits you?"
          message="Choose the date that you would like the plan to start and the flexible payment plan that suits you best."
        />

        {/* Start At Section */}
        <div className="max-w-sm px-4 mx-auto mt-4 space-y-2 sm:px-6">
          <h4 className="font-semibold text-center">
            Choose when payments will start
          </h4>
          <DatePicker
            error={dateError}
            setError={setDateError}
            minDate={today}
            maxDate={endDate}
            onChange={date => {
              patch({instalmentStartAt: date.toISO()}, {replace: true});
              onSetStartDate(date);
            }}
            value={startDate ?? undefined}
          />
        </div>
        {startDate && (
          <div className="mt-4">
            {/* Pricing Section */}
            <section className="relative" aria-labelledby="pricing-heading">
              <h2 id="pricing-heading" className="sr-only">
                Pricing
              </h2>

              {/* Tiers */}
              <div className="max-w-sm px-4 mx-auto space-y-4 sm:px-6 ">
                {!plans && loading && (
                  <div className="min-h-[464px]">
                    <Loading />
                  </div>
                )}
                {(plans?.length === 0 || error) && <NoPlanOptions />}
                {plans && (
                  <StyledRadioGroup
                    options={plans.map(plan => ({
                      id: `plan-frequency-${plan.frequency}`,
                      label: (
                        <p className="flex items-baseline mt-4 text-gray-900">
                          <span className="text-3xl font-extrabold tracking-tight">
                            <NumberFormat
                              value={formatToDollars(plan.amountPerInstalment)}
                              displayType={'text'}
                              decimalSeparator="."
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          </span>
                          <span className="ml-1 text-xl font-semibold">
                            /{plan.frequency}
                          </span>
                        </p>
                      ),
                      description: (
                        <span
                          className={classNames(
                            'text-slate-400 text-left mt-1 block py-1 border border-transparent rounded-md font-xs italic'
                          )}
                        >
                          {plan.frequency === 'weekly'
                            ? `${plan.instalmentCount} payments on the same weekday of`
                            : plan.frequency === 'fortnightly'
                              ? `${plan.instalmentCount} ${plan.frequency} payments on the same weekday of`
                              : plan.frequency === 'monthly'
                                ? `${plan.instalmentCount} ${plan.frequency} payments on the same date of the calendar month of`
                                : `${plan.instalmentCount} ${plan.frequency} payments of`}{' '}
                          <NumberFormat
                            value={formatToDollars(plan.amountPerInstalment)}
                            displayType={'text'}
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            prefix={'$'}
                          />{' '}
                          {plan?.remainderAmount && (
                            <>
                              each, and a final payment of{' '}
                              <NumberFormat
                                value={formatToDollars(plan.remainderAmount)}
                                displayType={'text'}
                                decimalSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </>
                          )}
                          {billerConfig.extendPlanTerms && (
                            <>. Plus interest as it is accrued.</>
                          )}
                        </span>
                      ),
                      value: plan.frequency,
                    }))}
                    onChange={freq => setFrequency(freq as typeof frequency)}
                    value={frequency}
                  />
                )}

                {arrangementPath &&
                (accountHasArrears ||
                  billerConfig.alwaysOfferAlternativePlans) ? (
                  <AlternativePlans
                    onClick={() => {
                      navigate(arrangementPath, {
                        instalmentFrequency: frequency,
                      });
                    }}
                  />
                ) : null}
                <RolloverNotice showNotice={hasYearlyRollOver} />
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => {
                    if (!dateError) {
                      navigate('/biller/:slug/setup/plan/preview', {
                        mode: 'PAY_EVERY_X',
                        instalmentFrequency: frequency,
                        instalmentMode: 'pay-every-x',
                      });
                    }
                  }}
                  disabled={!plans?.length}
                >
                  Set up flexible payments
                </button>
                <button
                  className="w-full mt-6 text-center text-blue-600 transition hover:text-blue-700"
                  onClick={goBack}
                >
                  Back
                </button>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
};
