import React, {useEffect, useState} from 'react';
import {RadioGroup} from '@headlessui/react';

import {Debbie} from 'components/organisms/Debbie';

import {getBillerSlugFromUrl} from 'lib/url';

import {TryAgain} from 'components/molecules/TryAgain';
import {TokenExCreditCardForm} from 'components/organisms/TokenExCreditCardForm';
import {DirectDebit} from 'components/organisms/DirectDebit';
import {
  PaymentMethodPicker,
  PaymentType,
} from 'components/organisms/PaymentMethodPicker';
import {Loading} from 'components/atoms/Loading';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {goBack, navigate as navigateTo} from 'lib/navigation/routes';
import {useSubmitSetupMutation} from '../hooks/useSubmitSetupMutation';
import {useSetupRoute} from '../components/SetupRoute';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {DirectDebitNZ} from '../../../components/organisms/DirectDebitNZ';
import {NoticeOnSurcharge} from '../shared/NoticeOnSurcharge';
import {useAPIMutation} from 'lib/api';
import {useUser} from 'lib/auth/useUser';
import {useGetAuthKey} from 'lib/auth/useGetAuthKey';

export const PaymentMethod: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const user = useUser();
  const navigate = useSetupNavigate();
  const {account, setCompletedState} = useSetupRoute();
  const {data: authKey, isLoading: loadingAuthKey} = useGetAuthKey({});
  const {mode, amountInCents} = useSetupSearchParams();
  const [paymentType, setPaymentType] = useState<PaymentType | undefined>();
  const [completed, setCompleted] = useState(false);

  const {
    mutateAsync: updateContact,
    isPending: isUpdatingContact,
    error: updatingContactError,
  } = useAPIMutation('updateContact');
  const {
    data: addPaymentMethodResult,
    mutateAsync: addPaymentMethod,
    isPending: addingPaymentMethod,
    error: addPaymentMethodError,
  } = useAPIMutation('addPaymentMethod');

  const {
    pay,
    loading: paying,
    error: errorPaying,
    payment,
    instalmentPlan,
    planRequest,
  } = useSubmitSetupMutation({mode});

  /**
   * Only allow customers to start receiving receipts in this flow when it is
   * their first time on their journey. After that it should only be available
   * in the profile or notification preferences.
   */
  const [showSendReceipts] = useState<boolean>(!user.contact?.email);

  useEffect(() => {
    if (
      completed &&
      !errorPaying &&
      (payment || instalmentPlan || planRequest)
    ) {
      setCompletedState({
        mode,
        contact: addPaymentMethodResult!.contact,
        paymentMethod: addPaymentMethodResult!.paymentMethod,
        payment,
        instalmentPlan,
        planRequest,
      });
      navigate('/biller/:slug/setup/confirmation');
    }
  }, [
    completed,
    mode,
    addPaymentMethodResult,
    payment,
    instalmentPlan,
    planRequest,
  ]);

  const loading = loadingAuthKey;

  if (loading) return <Loading />;

  if (!loadingAuthKey && !authKey) {
    return (
      <TryAgain
        errorMessage="No auth key"
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  const isPaying = paying || addingPaymentMethod || isUpdatingContact;

  const onCardComplete: React.ComponentProps<
    typeof TokenExCreditCardForm
  >['onCardComplete'] = async ({card, contact, sendReceipts}) => {
    await updateContact({...contact, sendReceipt: sendReceipts});

    const result = await addPaymentMethod({
      type: 'card',
      ...card,
    });

    await pay(amountInCents, {
      account,
      contact: result!.contact,
      paymentMethod: result!.paymentMethod,
    });

    setCompleted(true);
  };

  const onBankComplete: React.ComponentProps<
    typeof DirectDebit
  >['onComplete'] = async ({bank, contact, sendReceipts}) => {
    await updateContact({...contact, sendReceipt: sendReceipts});

    const result = await addPaymentMethod({
      type: 'direct_debit',
      ...bank,
    });

    await pay(amountInCents, {
      account,
      contact: result!.contact,
      paymentMethod: result!.paymentMethod,
    });

    setCompleted(true);
  };

  const onNZBankComplete: React.ComponentProps<
    typeof DirectDebitNZ
  >['onComplete'] = async ({contact, sendReceipts, nzBank}) => {
    await updateContact({...contact, sendReceipt: sendReceipts});

    const result = await addPaymentMethod({
      type: 'nz_direct_debit',
      accountName: nzBank.accountName,
      accountNumber: nzBank.accountNumber.toJSON(),
    });

    await pay(amountInCents, {
      account,
      contact: result!.contact,
      paymentMethod: result!.paymentMethod,
    });

    setCompleted(true);
  };

  return (
    <div className="relative" data-testid="paymentMethod">
      <Debbie
        title="You're almost there!"
        message="Choose your payment method."
      />

      <div className="relative" id="payment-section">
        <RadioGroup value={paymentType} onChange={setPaymentType}>
          <PaymentMethodPicker
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            billerSlug={billerSlug}
            accountType={account.type}
            disabled={loading || isPaying}
          />
        </RadioGroup>

        <NoticeOnSurcharge
          paymentMethodType="card"
          showNotice={!paymentType}
          billerSlug={billerSlug}
        />

        <div
          className={
            paymentType?.type === 'nz_direct_debit' ? 'min-w-full' : 'hidden'
          }
        >
          <DirectDebitNZ
            onComplete={onNZBankComplete}
            onCompleteError={
              updatingContactError?.message ??
              addPaymentMethodError?.message ??
              errorPaying
            }
            contact={user.contact}
            showSendReceipts={showSendReceipts}
            disabled={loading || isPaying}
          />
        </div>

        <div
          className={
            paymentType?.type === 'direct_debit' ? 'min-w-full' : 'hidden'
          }
        >
          <DirectDebit
            onComplete={onBankComplete}
            onCompleteError={
              updatingContactError?.message ??
              addPaymentMethodError?.message ??
              errorPaying
            }
            contact={user.contact}
            showSendReceipts={showSendReceipts}
            disabled={loading || isPaying}
          />
        </div>
        {paymentType?.type === 'card' ? (
          <div className="min-w-full">
            <TokenExCreditCardForm
              onCardComplete={onCardComplete}
              onCompleteError={
                updatingContactError?.message ??
                addPaymentMethodError?.message ??
                errorPaying
              }
              contact={user.contact}
              showSendReceipts={showSendReceipts}
              disabled={loading || isPaying}
            />
          </div>
        ) : null}
      </div>

      {!paymentType && (
        <button
          onClick={goBack}
          className="w-full mt-6 text-blue-600 transition hover:text-blue-700"
        >
          Back
        </button>
      )}
    </div>
  );
};
