import {getBillerSlugFromUrl} from 'lib/url';
import {SessionV1, loadSessionData} from 'lib/auth/initialisation';
import {useEffect} from 'react';
import {AbsoluteDate, getBillerConfig} from 'payble-shared';
import {usePatchSearchParams} from 'lib/navigation/usePatchSearchParams';
import {useUser} from 'lib/auth/useUser';
import {APIInput, APIOutput} from 'payble-api-client';

type GetInstalmentPlanInput = NonNullable<
  APIOutput<'consumer', 'getInstalmentPlan'>
>;

export type SetupSearchParams = {
  accountType: string;
  accountExternalId: string;
  verificationCode: string;
  lookingUpAnother: string;
  mode: string;
  amountInCents: string;
  instalmentStartAt: string;
  instalmentFrequency: string;
  instalmentMode: string;
  instalmentTargetDate: string;
  paymentStartDate: string;
  paymentTargetDate: string;
  isPlanPreviewed: string;
  nextNotice: string;
};

export type SetupSearchParamKey = keyof SetupSearchParams;

export function useSetupSearchParams() {
  const user = useUser();
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = getBillerConfig(billerSlug);
  const accountTypesInfo = billerConfig.getAccountTypesInfo();
  const authStateSession = user.sessionData;
  const {params, patch} = usePatchSearchParams<SetupSearchParams>({
    accountType: accountTypesInfo[0].id,
    accountExternalId: '',
    verificationCode: '',
    lookingUpAnother: '',
    mode: '',
    amountInCents: '',
    instalmentStartAt: '',
    instalmentFrequency: '',
    instalmentTargetDate: '',
    instalmentMode: '',
    paymentStartDate: '',
    paymentTargetDate: '',
    isPlanPreviewed: '',
    nextNotice: '',
  });

  useEffect(() => {
    const session = (
      authStateSession?.type === 'empty' ? loadSessionData() : authStateSession
    ) as SessionV1;

    if (session) {
      const initialParamsFromSession = {} as SessionV1;
      if (!params.accountExternalId) {
        initialParamsFromSession.accountExternalId = session.accountExternalId;
      }

      if (session.accountType) {
        initialParamsFromSession.accountType = session.accountType;
      }

      if (!params.verificationCode) {
        initialParamsFromSession.verificationCode = session.verificationCode;
      }
      if (Object.keys(initialParamsFromSession)?.length) {
        patch(initialParamsFromSession);
      }
    }
  }, [params, authStateSession, patch]);

  const {
    accountType,
    accountExternalId,
    verificationCode,
    lookingUpAnother,
    mode,
    amountInCents,
    instalmentMode,
    instalmentStartAt,
    instalmentTargetDate,
    instalmentFrequency,
    paymentStartDate,
    paymentTargetDate,
    isPlanPreviewed,
    nextNotice,
  } = params;

  return {
    accountType,
    accountExternalId,
    verificationCode,
    lookingUpAnother,
    mode,
    amountInCents: parseInt(amountInCents || '0', 10) || 0,
    instalmentMode: instalmentMode as GetInstalmentPlanInput['mode'],
    instalmentFrequency: instalmentFrequency as NonNullable<
      APIInput<'consumer', 'createInstalmentPlan'>
    >['frequency'],
    instalmentStartAt: AbsoluteDate.maybeFromISO(instalmentStartAt),
    instalmentTargetDate: AbsoluteDate.maybeFromISO(instalmentTargetDate),
    paymentStartDate: AbsoluteDate.maybeFromISO(paymentStartDate),
    paymentTargetDate: AbsoluteDate.maybeFromISO(paymentTargetDate),
    isPlanPreviewed,
    nextNotice,
    patch,
  };
}
