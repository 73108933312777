import {FC} from 'react';
import {useFormState} from 'react-hook-form';

interface FormFieldErrorProps {
  name: string;
}

export const FormFieldError: FC<FormFieldErrorProps> = ({name}) => {
  const state = useFormState();
  const error = name
    .split('.')
    .reduce((acc, key) => (acc as any)?.[key], state.errors);

  if (error)
    return (
      <div className="text-xs text-destructive">
        {error.message?.toString()}
      </div>
    );

  return null;
};
