import {z} from 'zod';
import {accountResponse} from './account';
import {paymentMethodResponse} from './contact';
import {zAbsoluteDate, zISODate} from './generic';

export const planFrequency = z.enum([
  'weekly',
  'fortnightly',
  'monthly',
  'quarterly',
  'automatic',
  'annually',
  'fixed',
  'end_of_month',
]);

export type PlanFrequency = z.infer<typeof planFrequency>;

export const planPayMode = z.enum([
  'pay-every-x',
  'balanced-pay-every-x',
  'pay-on-y',
  'pay-x-on-y',
  'pay-x-every-z',
  'smooth-pay',
  'catch-up',
]);

const ownerType = z.enum(['PAYER', 'CONTACT']);

export const instalmentType = z.enum(['regular', 'setup']);

export const instalmentBreakdown = z.object({
  amount: z.number(),
  label: z.string(),
});

const instalment = z.object({
  instalmentId: z.string(),
  dueAt: zAbsoluteDate,
  status: z
    .enum([
      'cancelled',
      'missed',
      'overdue',
      'paid',
      'processing',
      'refunded',
      'scheduled',
      'skipped',
    ])
    .optional(),
  amount: z.number(),
});

const tag = z.object({
  tagId: z.string(),
  label: z.string(),
  color: z.enum(['default', 'secondary', 'destructive', 'accent', 'warning']),
  internalKey: z.string().optional(),
});

const planStatus = z.enum([
  'pending',
  'on_track',
  'overdue',
  'processing',
  'completed',
  'cancelled',
]);

export const planResponse = z.object({
  amount: z.number(),
  amountPaid: z.number(),
  amountDue: z.number(),
  instalments: z.array(instalment),
});

export type PlanResponseType = z.infer<typeof planResponse>;

export const instalmentSchema = z.object({
  instalmentId: z.string(),
  amount: z.number(),
  dueAt: zAbsoluteDate,
  paidAt: zISODate.nullable(),
  refundedAt: zISODate.nullable(),
  cancelledAt: zISODate.nullable(),
  missedAt: zISODate.nullable(),
  skippedAt: zISODate.nullable(),
  status: z.enum([
    'scheduled',
    'processing',
    'overdue',
    'paid',
    'refunded',
    'cancelled',
    'missed',
    'skipped',
  ]),
  type: instalmentType,
  breakdown: z.array(instalmentBreakdown).optional(),
});

export const consumerInstalmentFrequency = z.enum([
  'annually',
  'automatic',
  'fortnightly',
  'monthly',
  'quarterly',
  'weekly',
]);

export type InstalmentSchema = z.infer<typeof instalmentSchema>;

export const instalmentPlanResponse = z.object({
  id: z.string(),
  amount: z.number(),
  mode: planPayMode,
  status: planStatus,
  frequency: planFrequency.optional(),
  instalments: z.array(instalmentSchema),
  nextInstalmentDueAt: zAbsoluteDate.optional(),
  cancelledReason: z.string(),
  targetDate: zAbsoluteDate.nullable(),
  paymentMethodId: z.string(),
  account: accountResponse,
  accountId: z.string(),
  amountDue: z.number(),
  amountPaid: z.number(),
  billerId: z.string(),
  createdAt: zISODate,
  cancelledAt: zISODate.nullable(),
  completedAt: zISODate.nullable(),
  currency: z.string(),
  testMode: z.boolean(),
  canUpdateInstalmentAmount: z.boolean(),
  tags: z.array(tag),
  contactPaymentMethod: paymentMethodResponse,
  events: z.array(
    z.object({
      id: z.string(),
      entityId: z.string(),
      type: z.string(),
      createdAt: zISODate,
    })
  ),
});

export type InstalmentPlanResponse = z.infer<typeof instalmentPlanResponse>;

const billerInstalmentPlan = z.object({
  id: z.string(),
  mode: planPayMode,
  frequency: planFrequency.optional(),
  instalments: z.array(instalmentSchema),
  currency: z.string(),
  amount: z.number(),
  amountPaid: z.number(),
  amountDue: z.number(),
  nextInstalmentDueAt: zAbsoluteDate.nullable(),
  accountId: z.string(),
  billerId: z.string(),
  createdByAdminId: z.string().nullable(),
  paymentMethodId: z.string(),
  testMode: z.boolean(),
  status: planStatus,
  cancelledReason: z.string(),
  createdAt: zISODate,
  cancelledAt: zISODate.nullable(),
  completedAt: zISODate.nullable(),
  account: z.object({
    type: z.string(),
    externalId: z.string(),
    description: z.string(),
  }),
  rebalance: z.object({
    outcome: z.string(),
    cause: z.string(),
  }),
  tags: z.array(tag),
  paymentMethod: paymentMethodResponse,
  ownerType: ownerType,
});

export type BillerInstalmentPlanResponse = z.infer<typeof billerInstalmentPlan>;

export const billerInstalmentPlansResponse = z.array(billerInstalmentPlan);

export const previewInstalmentSchema = z.object({
  amount: z.number(),
  dueAt: zAbsoluteDate,
  type: instalmentType,
  breakdown: z.array(instalmentBreakdown).optional().nullable(),
});

export type PreviewInstalmentSchema = z.infer<typeof previewInstalmentSchema>;

const BalancedInstalmentPreviewGroupSchema = z.object({
  amount: z.number(),
  dueAt: zAbsoluteDate,
  instalments: z.array(previewInstalmentSchema),
});

export type BalancedInstalmentPreviewGroupSchema = z.infer<
  typeof BalancedInstalmentPreviewGroupSchema
>;

export const previewInstalmentPlanResponse = z.object({
  amount: z.number(),
  frequency: planFrequency.nullable(), // TODO: ask why biller has more frequencies, might face a problem if biller set to that
  balancedInstalmentPreview: z.array(BalancedInstalmentPreviewGroupSchema),
  tags: z.array(
    z.object({
      tagId: z.string(),
      color: z.string(),
      label: z.string(),
      internalKey: z.string().optional(),
    })
  ),
});
