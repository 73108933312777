import {DateTime} from 'luxon';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {APIOutput} from 'payble-api-client';
import {ReactNode} from 'react';

export const ConfirmationPaymentDateSection = ({
  payment,
  instalmentPlan,
}: {
  payment?: APIOutput<'consumer', 'payAccountAmount'>;
  instalmentPlan?: APIOutput<'consumer', 'createInstalmentPlan'>;
}) => {
  const billerConfig = useBillerConfig();
  if (payment?.mode === 'pay-in-full') {
    return (
      <PaymentDateSectionCopy
        label={'Payment date'}
        description={DateTime.local({zone: 'Australia/Sydney'}).toLocaleString(
          DateTime.DATE_FULL
        )}
      />
    );
  }
  if (payment?.mode === 'pay-amount') {
    return (
      <PaymentDateSectionCopy
        label={'Payment date'}
        description={DateTime.local({zone: 'Australia/Sydney'}).toLocaleString(
          DateTime.DATE_FULL
        )}
      />
    );
  }

  if (instalmentPlan) {
    const label =
      instalmentPlan.mode === 'pay-on-y'
        ? 'Payment date'
        : 'First payment date';

    return (
      <PaymentDateSectionCopy
        label={label}
        description={
          instalmentPlan.nextInstalmentDueAt
            ?.toDateTime({billerConfig})
            ?.toLocaleString(DateTime.DATE_FULL) ?? 'Check your notice'
        }
      />
    );
  }

  return null;
};

function PaymentDateSectionCopy({
  label,
  description,
}: {
  label: string;
  description: ReactNode;
}) {
  return (
    <>
      <p id="payment-date-label" className="text-sm text-gray-600">
        {label}
      </p>
      <p id="payment-date-description" className="text-sm text-gray-600">
        {description}
      </p>
    </>
  );
}
