import {z} from 'zod';

type SchemaInput = z.Schema | null;

export type InferOutput<TSchema extends SchemaInput> = TSchema extends z.Schema
  ? z.infer<TSchema>
  : void;

export type InferOutputInput<TSchema extends SchemaInput> =
  TSchema extends z.Schema ? z.input<TSchema> : void;

export type InferInput<TSchema extends SchemaInput> = TSchema extends z.Schema
  ? z.input<TSchema>
  : never;

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type HTTPOperation = `${HTTPMethod} /${string}`;

export type AuthnMethod = 'BILLER' | 'GLOBAL_ADMIN' | 'CONSUMER' | 'PUBLIC';

type QueryKeyItem = string | number | boolean | object | null | undefined;

type QueryKey = QueryKeyItem[] | null;

type TypeEquality<T, U> = keyof T extends keyof U
  ? keyof U extends keyof T
    ? true
    : false
  : false;

type QueryKeyFactory<
  TRequestSchema extends SchemaInput,
  TKey extends QueryKey,
> = (
  request: InferInput<TRequestSchema> | null
) => TypeEquality<TKey, QueryKey> extends true ? never : TKey;

export type HTTPEndpointDefinition<
  TRequestSchema extends SchemaInput,
  TResponseSchema extends SchemaInput,
  TOperation extends HTTPOperation,
  TAuthn extends AuthnMethod,
  TKey extends QueryKey,
> = {
  operation: TOperation;
  authn: TAuthn;
  method: HTTPMethod;
  path: string;
  responseSchema: TResponseSchema;
  requestSchema: TRequestSchema;
  queryKey: QueryKeyFactory<TRequestSchema, TKey>;
};

export type HTTPEndpointResponse<T extends AnyHTTPEndpointDefinition> =
  InferOutput<T['responseSchema']>;

export type HTTPEndpointRequestData<T extends AnyHTTPEndpointDefinition> =
  InferInput<T['requestSchema']>;

export type HTTPQueryKey<T extends AnyHTTPEndpointDefinition> = ReturnType<
  T['queryKey']
>;

export type AnyAPISpec = Record<string, AnyHTTPEndpointDefinition>;

/**
 * Given a type ['a', 'b', 'c']
 * return a type
 * ['a'] | ['a', 'b'] | ['a', 'b', 'c']
 */
type ArrayPartial<T extends any[]> = T extends [infer A, ...infer B]
  ? [A] | [A, ...ArrayPartial<B>]
  : [];

export type QueryKeysOf<T extends AnyAPISpec> = {
  [K in keyof T]: ArrayPartial<HTTPQueryKey<T[K]>>;
}[keyof T];

type HTTPEndpointDefiner = <
  TOperation extends HTTPOperation,
  TRequestSchema extends SchemaInput,
  TResponseSchema extends SchemaInput,
  TAuthn extends AuthnMethod,
  const TKey extends QueryKey,
>(definition: {
  operation: TOperation;
  requestSchema: TRequestSchema;
  responseSchema: TResponseSchema;
  authn: TAuthn;
  queryKey?: (request: InferInput<TRequestSchema> | null) => TKey;
}) => HTTPEndpointDefinition<
  TRequestSchema,
  TResponseSchema,
  TOperation,
  TAuthn,
  TKey
>;

export type AnyHTTPEndpointDefinition = HTTPEndpointDefinition<
  SchemaInput,
  SchemaInput,
  HTTPOperation,
  AuthnMethod,
  any
>;

export const defineHTTPEndpoint: HTTPEndpointDefiner = definition => {
  const {operation, authn, requestSchema, responseSchema} = definition;

  const [method, path, ...rest] = operation.split(' ');
  if (rest.length > 0) {
    throw new Error(`Invalid operation format: ${operation}`);
  }

  const queryKey = (definition.queryKey ??
    (() => {
      throw new Error(`Query key not defined for ${operation}`);
    })) as any;

  return {
    authn,
    operation,
    method: method as HTTPMethod,
    path,
    requestSchema,
    responseSchema,
    queryKey,
  };
};
