import {z} from 'zod';
import {isDomainName} from '../../../util/text';
import {FormConfig} from '../../types';

export const bpoint = {
  title: 'BPOINT Gateway',
  configType: 'biller',

  validation: z.object({
    hostname: z.string().refine(isDomainName, {
      message: 'Invalid hostname',
    }),
    merchant: z.string().min(1, 'Merchant is required'),
    username: z.string().min(1, 'Username is required'),
    password: z.string().min(1, 'Password is required'),
    test: z.boolean().optional(),
    billerCodes: z
      .array(
        z.object({
          accountType: z.string().min(1),
          billerCode: z.string().min(1),
        })
        // Ensure both all account types and all biller codes are unique
      )
      .refine(
        data => {
          const accountTypes = new Set();
          for (const {accountType} of data) {
            if (accountTypes.has(accountType)) {
              return false;
            }
            accountTypes.add(accountType);
          }
          return true;
        },
        {
          message: 'Account types must be unique',
        }
      ),
  }),

  form: [
    {
      type: 'text',
      name: 'hostname',
      label: 'Hostname',
    },
    {
      type: 'text',
      name: 'merchant',
      label: 'Merchant',
    },
    {
      type: 'text',
      name: 'username',
      label: 'Username',
    },
    {
      type: 'password',
      name: 'password',
      label: 'Password',
    },

    {
      type: 'list',
      name: 'billerCodes',
      label: 'Biller Codes',
      fields: [
        {
          type: 'text',
          label: 'Account type',
          name: 'accountType',
          description: 'e.g. "rates", "water")',
        },
        {
          type: 'text',
          label: 'Biller Code',
          name: 'billerCode',
        },
      ],
    },
  ],
} satisfies FormConfig;
