import React, {ReactNode} from 'react';
import NumberFormat from 'react-number-format';
import {AbsoluteDate, fieldSorter, formatToDollars} from 'payble-shared';
import {} from '@heroicons/react/24/outline';
import {APIOutput} from 'payble-api-client';

function sortInstalmentsPreview(
  instalments: APIOutput<
    'consumer',
    'previewInstalmentPlan'
  >[number]['balancedInstalmentPreview'][number]['instalments']
) {
  return [...instalments].sort(
    // Sort setup instalments to the top of the list
    fieldSorter(v => (v.type === 'setup' ? null : v.dueAt), {
      dir: 'ASC',
      nulls: 'FIRST',
    })
  );
}

const PreviewHeader: React.FC = () => {
  return (
    <div className="flex py-2 text-xs font-semibold sm:text-sm">
      <div className="px-2 basis-6/12">Due Date</div>
      <div className="px-2 text-right basis-6/12">Amount</div>
    </div>
  );
};

const PreviewWrapper: React.FC<{children: ReactNode}> = ({children}) => {
  return <div className="mt-4 divide-y divide-gray-50">{children}</div>;
};

type PreviewRowProps = {
  type: 'regular' | 'setup';
  dueAt: AbsoluteDate;
  amount: number;
};
const PreviewRow: React.FC<PreviewRowProps> = ({type, dueAt, amount}) => {
  return (
    <div
      key={`${type}-${dueAt.toISO()}`}
      className={`py-2 flex text-xs sm:text-sm ${
        type === 'setup' ? 'text-red-500' : ''
      }`}
    >
      <div className="px-2 basis-6/12">
        <div>{dueAt.toFormat('dd MMM yyyy')}</div>
      </div>
      <div className="px-2 text-right basis-6/12">
        <NumberFormat
          value={formatToDollars(amount)}
          displayType={'text'}
          thousandSeparator={true}
          decimalSeparator={'.'}
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={'$'}
        />
      </div>
    </div>
  );
};

const TotalsRow: React.FC<{totalAmount: number}> = ({totalAmount}) => {
  return (
    <div className="flex py-2 text-xs sm:text-sm">
      <div className="px-2 font-bold basis-6/12">Total</div>
      <div className="px-2 font-bold text-right basis-6/12">
        <NumberFormat
          value={formatToDollars(totalAmount)}
          displayType={'text'}
          thousandSeparator={true}
          decimalSeparator={'.'}
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={'$'}
        />
      </div>
    </div>
  );
};
export const BalancedInstalmentTablePreview: React.FC<{
  balancedGroups: APIOutput<
    'consumer',
    'previewInstalmentPlan'
  >[number]['balancedInstalmentPreview'];
  shouldDisplayGroups: boolean;
  shouldShowTotal: boolean;
}> = ({balancedGroups, shouldDisplayGroups, shouldShowTotal}) => {
  const total = balancedGroups.reduce((acc, group) => acc + group.amount, 0);

  if (!shouldDisplayGroups) {
    return (
      <InstalmentTablePreview
        instalments={balancedGroups.flatMap(group => group.instalments)}
        total={total}
        shouldShowTotal={shouldShowTotal}
      />
    );
  }

  return (
    <PreviewWrapper>
      {balancedGroups.map(instalmentGroup => {
        return (
          <div key={instalmentGroup.dueAt.toISO()}>
            <h4 className="mb-2 ml-2 text-sm font-bold text-left sm:text-lg">
              Instalment due at: {instalmentGroup.dueAt.toFormat('dd MMM yyyy')}
            </h4>
            <PreviewHeader />

            {sortInstalmentsPreview(instalmentGroup.instalments)?.map(i => {
              const {dueAt} = i;

              return (
                <PreviewRow type={i.type} dueAt={dueAt} amount={i.amount} />
              );
            })}

            <div className="flex py-2 text-xs sm:text-sm">
              <div className="px-2 basis-6/12">Instalment total:</div>
              <div className="px-2 text-right basis-6/12">
                <NumberFormat
                  value={formatToDollars(instalmentGroup.amount)}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalSeparator={'.'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                />
              </div>
            </div>
          </div>
        );
      })}
      {shouldShowTotal ?? <TotalsRow totalAmount={total} />}
    </PreviewWrapper>
  );
};

type InstalmentTablePreviewProps = {
  instalments: APIOutput<
    'consumer',
    'previewInstalmentPlan'
  >[number]['balancedInstalmentPreview'][number]['instalments'];
  total: number;
  shouldShowTotal: boolean;
};

export function InstalmentTablePreview({
  instalments,
  total,
  shouldShowTotal,
}: InstalmentTablePreviewProps) {
  return (
    <PreviewWrapper>
      <PreviewHeader />
      {sortInstalmentsPreview(instalments).map(instalment => {
        const {dueAt} = instalment;

        return (
          <PreviewRow
            type={instalment.type}
            dueAt={dueAt}
            amount={instalment.amount}
            key={`${instalment.dueAt.toISO()}-${instalment.type}`}
          />
        );
      })}
      {shouldShowTotal && <TotalsRow totalAmount={total} />}
    </PreviewWrapper>
  );
}
