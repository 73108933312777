import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {useAPI, useAPIMutation} from 'lib/api';
import {history} from 'lib/navigation/routes';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {clear, safeValidate, storeCredentials} from './token';
import {AuthenticationError, DomainError} from 'payble-shared/src/errs';
import {analytics} from '../../analytics/hooks/useAnalytics';
import {getBillerSlugFromUrl} from 'lib/url';

function removeLoggedOutFromHash() {
  const removeLoggedFromHash = window.location?.hash
    .substring(1)
    .replace('logged-out', '');

  window?.history?.pushState(
    '',
    document.title,
    window.location.pathname +
      window.location.search +
      (removeLoggedFromHash ? '#' + removeLoggedFromHash : '')
  );
}

interface AuthContext {
  sendOTPError: DomainError | null;
  sendOTP: (params: {
    mobileNumber: string;
  }) => Promise<{formattedPhoneNumber: string}>;
  verifyOTPError: DomainError | null;
  verifyOTP: (params: {
    mobileNumber: string;
    verificationCode: string;
  }) => Promise<{id_token: string}>;
  logout: () => Promise<void>;
  isLoggedIn: boolean;
}

export const Context = React.createContext<AuthContext>({} as AuthContext);

export const useAuth = () => React.useContext(Context);

function getPageInfoFromPath() {
  const slug = getBillerSlugFromUrl();

  // This is quite important. It moves known domains for example 'payble.kingston.vic.gov.au'
  // to use the URL scheme of the SPA. It's not a long term option but is needed to make the
  // QR codes and magic link logins work.
  const noBillerSlugPath =
    window.location.pathname.indexOf('/biller/') === -1 && slug;

  const isAuthRequired =
    window.location.pathname.indexOf('/infringements/') === -1 &&
    window.location.pathname.indexOf('/pay-now') === -1 &&
    window.location.pathname.indexOf('verify-email') === -1;

  return {
    noBillerSlugPath,
    isAuthRequired,
  };
}

export const useAuthPageInfo = () => {
  const [authPageInfo, setAuthPageInfo] = useState(getPageInfoFromPath());

  useEffect(() => {
    const unListen = history.listen(() => {
      setAuthPageInfo(getPageInfoFromPath());
    });

    return () => {
      unListen();
    };
  });

  return authPageInfo;
};

const useHandleAuthState = () => {
  const {billerSlug} = useBillerConfig();
  const [isLoggedIn, setIsLoggedIn] = useState(safeValidate());

  const login = useCallback(() => {
    setIsLoggedIn(true);
  }, [setIsLoggedIn]);

  const logout = useCallback(
    (type: 'logged-out' | 'session-expired' = 'logged-out') => {
      clear();
      window.location.href = `/biller/${billerSlug}/login#${type}`;
    },
    [billerSlug]
  );

  return {
    isLoggedIn,
    login,
    logout,
  };
};

export const Provider: React.FC<{children: ReactNode}> = ({children}) => {
  const {billerSlug} = useBillerConfig();
  const {isLoggedIn, login, logout} = useHandleAuthState();
  const {api} = useAPI();

  useEffect(
    () =>
      api.errorTopic.subscribe(({error}) => {
        if (
          error.context.authn !== 'PUBLIC' &&
          error instanceof AuthenticationError
        ) {
          logout('session-expired');
        }
      }),
    [api.errorTopic, logout]
  );

  const logoutMutation = useAPIMutation('logout', {
    query: {
      onMutate: () => {
        analytics.addEvent('logged_out');
      },
      onSuccess: () => {
        logout();
      },
    },
  });

  const loginMutation = useAPIMutation('loginStart', {
    query: {
      onSuccess: () => {
        analytics.addEvent('auth_sms_sent');
        removeLoggedOutFromHash();
      },
      onError: () => {
        analytics.addEvent('auth_sms_failed');
      },
    },
  });

  const verifyMutation = useAPIMutation('loginComplete', {
    query: {
      onSuccess: (credentials: {id_token: string}) => {
        storeCredentials(credentials);
        api.setAuth({
          type: 'consumer',
          token: credentials.id_token,
        });
        login();
      },
      onError: () => {
        analytics.addEvent('auth_code_failed');
      },
    },
  });

  const contextValue = React.useMemo<AuthContext>(
    () => ({
      sendOTPError: loginMutation.error,
      sendOTP: ({mobileNumber}) =>
        loginMutation.mutateAsync({mobileNumber, billerSlug}),
      verifyOTPError: verifyMutation.error,
      verifyOTP: ({mobileNumber, verificationCode}) =>
        verifyMutation.mutateAsync({
          mobileNumber,
          billerSlug,
          verificationCode,
        }),
      logout: async () => {
        await logoutMutation.mutateAsync(null as never);
        logout();
      },
      isLoggedIn,
    }),
    [loginMutation, verifyMutation, logoutMutation, isLoggedIn]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
